import PropTypes from "prop-types";
import { useMemo } from "react";
import Translate from "./Translate";
import classNames from "classnames";

export default function SubmitBar(props) {
	const handleSubmit = (e, stayOnSamePage) => {
		if (typeof props.onSubmit === "function") {
			props.onSubmit(stayOnSamePage);
		}
	};

	const buttonContent = useMemo(() => {
		if (props.buttonContent) {
			return props.translateLabel ? <Translate content={props.buttonContent} /> : props.buttonContent;
		} else if (props.translateLabel) {
			return <Translate content="general.save_:item" transReplaces={{ item: props.item }} />;
		}
		return <Translate content="general.save_:item" replaces={{ item: props.item }} />;
	}, [props.buttonContent, props.item, props.translateLabel]);

	return (
		<div className="submit-bar">
			{props.hint && <span className="italic text-sm">{props.hint}</span>}
			<div className="submit-container">
				<button
					className={classNames("button background-color-success button-primary mr-5", {
						disabled: props.submitDisabled,
					})}
					type="submit"
					disabled={props.submitDisabled}
					onClick={e => handleSubmit(e, false)}>
					{props.isCopy ? (
						<span>
							{props.translateLabel ? (
								<Translate content="general.copy_:item" transReplaces={{ item: props.item }} />
							) : (
								<Translate content="general.copy_:item" replaces={{ item: props.item }} />
							)}
						</span>
					) : (
						<span>{buttonContent}</span>
					)}
				</button>
				{props.addAnotherAvailable && (
					<button className="button hollow" type="submit" onClick={e => handleSubmit(e, false)}>
						{props.translateLabel ? (
							<Translate content="general.save_:item_and_create_another" transReplaces={{ item: props.item }} />
						) : (
							<Translate content="general.save_:item_and_create_another" replaces={{ item: props.item }} />
						)}
					</button>
				)}
				{props.saveAndStayOnPage && (
					<button
						className={classNames("button background-color-success button-primary", {
							disabled: props.submitDisabled,
						})}
						type="submit"
						onClick={e => handleSubmit(e, true)}>
						{props.translateLabel ? (
							<Translate content="general.save_:item_and_stay_on_page" transReplaces={{ item: props.item }} />
						) : (
							<Translate content="general.save_:item_and_stay_on_page" replaces={{ item: props.item }} />
						)}
					</button>
				)}
				{(props.cancelUrl || props.onCancel) && (
					<a href={props.cancelUrl} onClick={props.onCancel} className="ml-4 text-button link">
						<Translate content="general.cancel" />
					</a>
				)}
			</div>
		</div>
	);
}

SubmitBar.propTypes = {
	item: PropTypes.string,
	addAnotherAvailable: PropTypes.bool,
	isCopy: PropTypes.bool,
	onCancel: PropTypes.func,
	onSubmit: PropTypes.func,
	cancelUrl: PropTypes.string,
	translateLabel: PropTypes.bool,
	submitDisabled: PropTypes.bool,
	saveAndStayOnPage: PropTypes.bool,
	buttonContent: PropTypes.string,
	hint: PropTypes.string,
};

SubmitBar.defaultProps = {
	addAnotherAvailable: false,
	saveAndStayOnPage: false,
	translateLabel: true,
	submitDisabled: false,
	isCopy: false,
	hint: null,
};
