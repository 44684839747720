import { format } from "../../lib/Date";

export default function useDateHandler(setState) {
	return (value, name) => {
		const date = value !== null ? format(value) : null;

		setState(state => ({
			...state,
			[name]: date,
		}));
	};
}
