import PropTypes from "prop-types";
import Checkmark from "../form/checkmark/Checkmark";
import Filter from "./Filter";
import classNames from "classnames";

class CheckboxFilter extends Filter {
	renderAs(context) {
		return (
			<div className={classNames("overview-filter", this.props.className)}>
				<Checkmark
					label={this.props.label}
					onChange={e => this.handleChange(context, e)}
					name={this.props.name}
					checked={!!context.data.metadata.filtering.filters[this.props.name]}
				/>
			</div>
		);
	}
}

export default CheckboxFilter;

CheckboxFilter.propTypes = {
	name: PropTypes.string,
	className: PropTypes.string,
	label: PropTypes.string,
};
