import PropTypes from "prop-types";
import { uctrans, trans } from "../../../../js/lib/Translator";
import AbnOverview from "../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../js/react/components/general/overview/DataColumn";
import { can, route } from "../../../../js/helpers";
import { format } from "../../../lib/Date";
import Nav from "../../../lib/Nav";
import EvaIcon from "../general/EvaIcon";
import CheckboxFilter from "../general/overview/CheckboxFilter";
import Filters from "../general/overview/Filters";
import SearchBarFilter from "../general/overview/SearchBarFilter";
import SelectFilter from "../general/overview/SelectFilter";
import TextFilter from "../general/overview/TextFilter";

export default function DiscussRequestOverviewContent(props) {
	const overviewData = { ...window.data.overview };

	const handleExport = overviewParameters => {
		Nav.go(route(`crm.discuss-requests.export`, overviewParameters));
	};

	return (
		<AbnOverview
			initialData={overviewData}
			indexUrl={route(props.indexRoute)}
			modelTranslations={{
				plural: trans("discuss_requests.plural"),
				singular: trans("discuss_requests.singular"),
			}}
			renderFilters={overview => (
				<Filters>
					<SearchBarFilter type="text" name="search" placeholder={uctrans("discuss_requests.search_placeholder")} />
					<CheckboxFilter
						className="form-full"
						name="hide_finished_discuss_requests"
						label={uctrans("discuss_requests.hide_finished_discuss_requests")}
					/>
					{!window.data.current_site_company && typeof window.data.user !== "undefined" && (
						<SelectFilter
							className="form-1-2"
							name="team_id"
							label={uctrans("intermediaries.fields.team")}
							options={overview.data.metadata.filterdata.teams}
						/>
					)}
					<SelectFilter
						className="form-1-2"
						name="status"
						label={uctrans("discuss_requests.fields.status")}
						options={overview.data.metadata.filterdata.statuses}
						collapsible
					/>
					<SelectFilter
						className="form-1-2"
						name="category_id"
						label={uctrans("discuss_requests.fields.category")}
						options={overview.data.metadata.filterdata.categories}
						collapsible
					/>
					{!!window.data.current_site_company && (
						<SelectFilter
							className="form-1-2"
							name="submitting_contactperson_id"
							label={uctrans("discuss_requests.fields.submitting_contactperson")}
							options={overview.data.metadata.filterdata.contactpersons}
							collapsible
						/>
					)}
					{!!window.data.current_site_company && (
						<SelectFilter
							className="form-1-2"
							name="treating_contactperson_id"
							label={uctrans("discuss_requests.fields.treating_contactperson")}
							options={overview.data.metadata.filterdata.contactpersons}
							collapsible
						/>
					)}
					{!window.data.current_site_company && typeof window.data.user !== "undefined" && (
						<SelectFilter
							className="form-1-2"
							name="relationmanager_id"
							label={uctrans("intermediaries.fields.relationmanager")}
							options={overview.data.metadata.filterdata.relationmanagers}
							collapsible
						/>
					)}
					{!window.data.current_site_company && typeof window.data.user !== "undefined" && (
						<SelectFilter
							className="form-1-2"
							name="segment_id"
							label={uctrans("intermediaries.fields.segment")}
							options={overview.data.metadata.filterdata.segments}
							collapsible
						/>
					)}
					{!window.data.current_site_company && typeof window.data.user !== "undefined" && (
						<SelectFilter
							className="form-1-2"
							name="organisation_id"
							label={uctrans("intermediaries.fields.organisation")}
							options={overview.data.metadata.filterdata.organisations}
							collapsible
						/>
					)}

					{!window.data.current_site_company && typeof window.data.user !== "undefined" && (
						<TextFilter
							hideIcon
							className="form-1-2"
							name="company_name"
							label={uctrans("intermediaries.singular")}
							placeholder={uctrans("general.search_:item", {}, { item: "intermediaries.singular" })}
							collapsible
						/>
					)}

					{!!props.forCrm && (
						<SelectFilter
							className="form-1-2"
							name="commercial"
							label={uctrans("discuss_requests.score.fields.commercial")}
							options={overview.data.metadata.filterdata.yes_no_options}
							collapsible
						/>
					)}
					{!!props.forCrm && (
						<SelectFilter
							className="form-1-2"
							name="relevant"
							label={uctrans("discuss_requests.score.fields.relevant")}
							options={overview.data.metadata.filterdata.yes_no_options}
							collapsible
						/>
					)}
				</Filters>
			)}
			renderBody={() => (
				<DataTable>
					<DataColumn
						width={3}
						sortable="id"
						renderHeaderContent={() => uctrans("discuss_requests.fields.number")}
						renderCellContent={discussRequest =>
							can("discuss_request.discuss_request", "update") ? (
								<a className="link" href={route(props.editRoute, discussRequest.id)}>
									{discussRequest.number}
								</a>
							) : (
								discussRequest.number
							)
						}
					/>
					<DataColumn
						width={4}
						sortable="datetime"
						renderHeaderContent={() => uctrans("discuss_requests.fields.datetime")}
						renderCellContent={discussRequest =>
							discussRequest.datetime
								? uctrans("general.:date_at_:time", {
										date: format(discussRequest.datetime, "dd-MM-y"),
										time: format(discussRequest.datetime, "HH:mm"),
								  })
								: "-"
						}
					/>
					<DataColumn
						width={4}
						sortable="last_reaction_datetime"
						renderHeaderContent={() => uctrans("discuss_requests.fields.last_reaction_datetime")}
						renderCellContent={discussRequest =>
							discussRequest.last_reaction_datetime
								? uctrans("general.:date_at_:time", {
										date: format(discussRequest.last_reaction_datetime, "dd-MM-y"),
										time: format(discussRequest.last_reaction_datetime, "HH:mm"),
								  })
								: discussRequest.datetime
								  ? uctrans("general.:date_at_:time", {
											date: format(discussRequest.datetime, "dd-MM-y"),
											time: format(discussRequest.datetime, "HH:mm"),
								    })
								  : "-"
						}
					/>
					{!window.data.current_site_company && (
						<DataColumn
							width={5}
							sortable="company_label"
							renderHeaderContent={() => uctrans("discuss_requests.fields.company")}
							renderCellContent={discussRequest => (discussRequest.company_label ? discussRequest.company_label : "-")}
						/>
					)}
					<DataColumn
						width={4}
						sortable="treating_contactperson_label"
						renderHeaderContent={() => uctrans("discuss_requests.fields.treating_contactperson")}
						renderCellContent={discussRequest =>
							discussRequest.treating_contactperson_label ? discussRequest.treating_contactperson_label : "-"
						}
					/>
					<DataColumn
						width={3}
						sortable="client_name"
						renderHeaderContent={() => uctrans("discuss_requests.fields.client")}
						renderCellContent={discussRequest => (discussRequest.client_name ? discussRequest.client_name : "-")}
					/>
					<DataColumn
						width={4}
						sortable="category_label"
						renderHeaderContent={() => uctrans("discuss_requests.fields.category")}
						renderCellContent={discussRequest => (discussRequest.category_label ? discussRequest.category_label : "-")}
					/>
					<DataColumn
						width={4}
						sortable="status"
						renderHeaderContent={() => uctrans("discuss_requests.fields.status")}
						renderCellContent={discussRequest => (discussRequest.status_label ? discussRequest.status_label : "-")}
					/>
					{props.forCrm && (
						<DataColumn
							width={1}
							renderHeaderContent={() => uctrans("discuss_requests.score.fields.has_score")}
							renderCellContent={discussRequest =>
								discussRequest.has_score ? (
									<span>
										<EvaIcon type="checkmark-circle-2" fill="#6FAD2B" height="18" width="18" />
									</span>
								) : null
							}
						/>
					)}
				</DataTable>
			)}
			renderFooter={overview =>
				can("discuss_request.discuss_request", "export") && (
					<a className="button button-primary mr-1" onClick={() => handleExport(overview.getParameters())}>
						{uctrans("discuss_requests.export")}
					</a>
				)
			}
		/>
	);
}

DiscussRequestOverviewContent.propTypes = {
	indexRoute: PropTypes.string,
	editRoute: PropTypes.string,
	forCrm: false,
};
