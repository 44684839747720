import { route } from "@/helpers";
import { uctrans } from "@/lib/Translator";
import { renderInputProps, renderRootProps } from "@crm/lib/fileInputHelpers";
import axios from "axios";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import EvaIcon from "../EvaIcon";
import { createLoader, error } from "../notifications";
import Translate from "../Translate";

export default function FileInput(props) {
	const [files, setFiles] = useState(props.initialFiles);
	const maxUploadInBytes = 1073741824;

	const handleChange = useCallback(
		files => {
			props.onChange(files);
			if (files.length > 0 && props.clearAfterUpload) {
				setFiles([]);
			} else {
				setFiles(files);
			}
		},
		[props],
	);

	const handleUpload = useCallback(
		async event => {
			const count = event.length;
			if (!count) {
				return;
			}

			if (props.onBeforeUpload) {
				props.onBeforeUpload();
			}

			let loader = null;
			if (props.useLoaders) {
				loader = createLoader(uctrans("file.file_uploading"));
			}

			if (props.maxFiles > 0 && count > props.maxFiles - files.length) {
				if (loader) {
					loader.failure(uctrans("file.too_many_files_selected"));
				}
				return;
			}

			let iteration = 0;
			let success = true;
			const uploadedFiles = [];
			const resultData = [];

			for (const file of event) {
				if (count > 1 && loader) {
					loader.update(uctrans("file.file_:number_of_:total_uploading", { number: ++iteration, total: count }));
				}

				if (file.size > maxUploadInBytes) {
					if (loader) {
						loader.failure(uctrans("file.file_too_large_uploading_failed"));
					} else {
						error(uctrans("file.file_too_large_uploading_failed"));
					}
					props.onFailure && props.onFailure();
					return;
				}

				try {
					const data = new FormData();
					data.append("file", file);
					data.append("public", props.public ? "1" : "0");
					if (props.fileType) {
						data.append("type", props.fileType);
					}
					const result = await axios.post(route(props.storeRoute), data, { maxContentLength: maxUploadInBytes });
					if (result.data) {
						uploadedFiles.push({
							id: result.data.id,
							id_token: result.data.id_token,
							filename: result.data.filename,
						});
					}
					resultData.push(result.data);
				} catch (exception) {
					if (exception.response && exception.response.data) {
						const { status, data } = exception.response;
						if (status === 422 && data.errors) {
							Object.keys(data.errors).forEach(field => {
								data.errors[field].forEach(err => error(err.charAt(0).toUpperCase() + err.slice(1)));
							});
						} else if (status === 400 && data.message) {
							error(exception.response.data.message);
						} else if (status === 413) {
							error(uctrans("file.file_too_large_uploading_failed"));
						} else {
							error(exception.message);
						}
					} else {
						error(exception.message);
					}
					success = false;
				}
			}

			if (success) {
				handleChange([...files, ...uploadedFiles]);
				props.onAfterUpload && props.onAfterUpload(resultData);
				if (loader) {
					loader.success(uctrans("file.file_uploading_complete"));
				}
			} else {
				props.onFailure && props.onFailure();
				if (loader) {
					loader.failure(uctrans("file.file_uploading_failed"));
				}
			}
		},
		[files, handleChange, props],
	);

	const deleteFile = useCallback(
		id => {
			handleChange(files.filter(file => file.id !== id));
		},
		[files, handleChange],
	);

	return (
		<div>
			{files.map((file, index) => (
				<div key={index} className=" border border-grey mb-2 p-3 flex justify-between">
					{!file.object_id && !!props.newFileDownloadRoute ? (
						<a className="link" href={route(props.newFileDownloadRoute, { idtoken: file.id_token })}>
							{file.filename}
						</a>
					) : !!file.object_id && !!props.existingFileDownloadRoute ? (
						props.additionalFileDownloadRouteProps ? (
							<a
								className="link"
								href={route(props.existingFileDownloadRoute, {
									...props.additionalFileDownloadRouteProps,
									file: file.id,
								})}>
								{file.filename}
							</a>
						) : (
							<a className="link" href={route(props.existingFileDownloadRoute, file.id)}>
								{file.filename}
							</a>
						)
					) : (
						<span>{file.filename}</span>
					)}
					<a onClick={() => deleteFile(file.id)}>
						<EvaIcon type="trash-2-outline" fill="#de751f" width="20" height="20" />
					</a>
				</div>
			))}

			{(props.maxFiles === 0 || files.length < props.maxFiles) && !!props.storeRoute && (
				<div>
					<label className={`button button-upload inline-block relative ${props.disabled ? "disabled" : ""}`}>
						<EvaIcon type="file-add-outline" className="absolute left-0 ml-3" fill="#ffffff" height="20" width="20" />
						<Dropzone onDrop={handleUpload} disabled={props.disabled}>
							{({ getRootProps, getInputProps }) => (
								<section>
									<div {...renderRootProps(getRootProps(), props)}>
										<input {...renderInputProps(getInputProps(), props, files)} />
										<p>
											<Translate content={props.pickAFileTranslation} />
										</p>
									</div>
								</section>
							)}
						</Dropzone>
					</label>
				</div>
			)}
		</div>
	);
}

FileInput.propTypes = {
	fileType: PropTypes.oneOf(["image", "document", "animation"]),
	storeRoute: PropTypes.string,
	pickAFileTranslation: PropTypes.string,
	newFileDownloadRoute: PropTypes.string,
	existingFileDownloadRoute: PropTypes.string,
	additionalFileDownloadRouteProps: PropTypes.object,
	maxFiles: PropTypes.number,
	acceptedExtensions: PropTypes.array,
	initialFiles: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			filename: PropTypes.string.isRequired,
		}),
	),
	public: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onBeforeUpload: PropTypes.func,
	onAfterUpload: PropTypes.func,
	onFailure: PropTypes.func,
	clearAfterUpload: PropTypes.bool,
	useLoaders: PropTypes.bool,
	disabled: PropTypes.bool,
};

FileInput.defaultProps = {
	maxFiles: 1,
	pickAFileTranslation: "file.drag_and_drop_a_file",
	initialFiles: [],
	public: false,
	clearAfterUpload: false,
	useLoaders: true,
	disabled: false,
};
