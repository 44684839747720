import PropTypes from "prop-types";
import { forwardRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import highcharts_global from "../../../../../../js/config/highcharts_global";

const HighChart = forwardRef(({ chartConfig }, ref) => {
	Highcharts.setOptions(highcharts_global);
	HighchartsMore.default(Highcharts);

	return <HighchartsReact options={chartConfig} ref={ref} highcharts={Highcharts} />;
});

HighChart.displayName = "HighChart";

export default HighChart;

HighChart.propTypes = {
	chartConfig: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
