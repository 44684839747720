import classNames from "classnames";
import PropTypes from "prop-types";
import Filter from "./Filter";
import SearchBar from "../SearchBar";

class SearchBarFilter extends Filter {
	renderAs(context) {
		const className = classNames(
			"overview-filter overview-filter-search relative my-2 form-full",
			this.props.className,
		);

		return (
			<div className={className}>
				<SearchBar
					name={this.props.name}
					placeholder={this.props.placeholder}
					value={context.data.metadata.filtering.filters[this.props.name]}
					onChange={e => this.handleChange(context, e)}
				/>
			</div>
		);
	}
}

export default SearchBarFilter;

SearchBarFilter.propTypes = {
	placeholder: PropTypes.string,
	name: PropTypes.string,
};

SearchBarFilter.defaultProps = {
	name: "search",
};
