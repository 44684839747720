import PropTypes from "prop-types";
import { Component } from "react";
import { isFragment } from "../../../../helpers";
import { uctrans } from "../../../../lib/Translator";
import EvaIcon from "../EvaIcon";
import Translate from "../Translate";
import { OverviewContext } from "./overview-context";

class Filters extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		if (this.props.onChange) {
			this.props.onChange(event);
		}
	}

	render() {
		return (
			<OverviewContext.Consumer>
				{context => (
					<>
						<div className="flex">
							<div className="flex-1 form-container filters non-collapsible -mr-3">
								{this.getNonCollapsibleFilterComponents()}
							</div>

							<div className="mt-10 pl-3">
								{this.hasCollapsibleFilters() && (
									<a
										className="link inline-flex mr-3"
										onClick={() => {
											this.toggleFilters(context);
										}}>
										<EvaIcon type="options-2" className="mr-1" fill="#009286" height="20" width="20" />
										{context.data.metadata.filtering.showCollapsible
											? uctrans("overview.hide_extra_filters")
											: uctrans("overview.show_extra_filters")}
									</a>
								)}
								{this.props.displayFilterResetter && this.hasActiveFilters(context) && (
									<a
										className="link inline-flex"
										onClick={() => {
											this.resetFilters(context);
										}}>
										<EvaIcon type="trash-2-outline" className="mr-1" fill="#009286" height="20" width="20" />
										<Translate
											content="overview.reset_filters_:cnt"
											replaces={{ cnt: context.getActiveFilterCount() }}
										/>
									</a>
								)}
							</div>
						</div>

						{this.hasCollapsibleFilters() && context.data.metadata.filtering.showCollapsible && (
							<div className="form-container filters collapsible">{this.getCollapsibleFilterComponents()}</div>
						)}
					</>
				)}
			</OverviewContext.Consumer>
		);
	}

	toggleFilters(context) {
		const data = { ...context.data };
		data.metadata.filtering.showCollapsible = !data.metadata.filtering.showCollapsible;
		context.setData(data);
	}

	resetFilters(context) {
		const data = { ...context.data };
		data.metadata.pagination.page = 1;
		data.metadata.filtering.filters = [];
		context.setData(data);
	}

	hasActiveFilters(context) {
		return context.getActiveFilterCount() > 0;
	}

	getNonCollapsibleFilterComponents() {
		return this.getFilterComponents().filter(filter => !filter.props.collapsible);
	}

	hasCollapsibleFilters() {
		return this.getFilterComponents().some(filter => filter.props.collapsible);
	}

	getCollapsibleFilterComponents() {
		return this.getFilterComponents().filter(filter => filter.props.collapsible);
	}

	getFilterComponents() {
		const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
		return children.filter(
			child => child && (child.type.displayName === "Filter" || child.type === "div" || isFragment(child)),
		);
	}
}

export default Filters;
Filters.displayName = "FilterTable";

Filters.propTypes = {
	children: PropTypes.node.isRequired,
	onChange: PropTypes.func,
	displayFilterResetter: PropTypes.bool,
};

Filters.defaultProps = {
	displayFilterResetter: true,
};
