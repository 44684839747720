import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import EvaIcon from "../EvaIcon";
import { OverviewContext } from "./overview-context";

class Pagination extends Component {
	constructor(props) {
		super(props);

		this.lastDebounceCall = 0;
	}

	debounce(callback) {
		const timeBefore = new Date().getTime();
		this.lastDebounceCall = timeBefore;

		window.setTimeout(() => {
			if (timeBefore === this.lastDebounceCall) {
				callback();
			}
		}, 400);
	}

	handlePaginationChange(overview, e) {
		const { target } = e;

		const handle = () => {
			this.goToPage(overview, target.value);
		};

		if (target.type === "number") {
			this.debounce(handle.bind(this));
		} else {
			handle();
		}
	}

	calculatePaginationState(overview) {
		return {
			overview,
			component: this,
			hasPreviousPage: overview.data.metadata.pagination.page > 1,
			hasNextPage: overview.data.metadata.pagination.page_count > overview.data.metadata.pagination.page,
			currentPage: overview.data.metadata.pagination.page,

			goToFirstPage: () => {
				this.goToFirstPage(overview);
			},
			goToPreviousPage: () => {
				this.goToPreviousPage(overview);
			},
			goToPage: pageNumber => {
				this.goToPage(overview, pageNumber.target.value);
			},
			goToNextPage: () => {
				this.goToNextPage(overview);
			},
			goToLastPage: () => {
				this.goToLastPage(overview);
			},
		};
	}

	goToFirstPage(overview) {
		if (overview.data.metadata.pagination.page <= 1) {
			throw "Overview does not have a first page.";
		}

		const { data } = overview;
		data.metadata.pagination.page = 1;
		overview.setData(data);
	}

	goToPreviousPage(overview) {
		if (overview.data.metadata.pagination.page <= 1) {
			throw "Overview does not have a previous page.";
		}

		const { data } = overview;
		data.metadata.pagination.page = data.metadata.pagination.page - 1;
		overview.setData(data);
	}

	goToPage(overview, pageNumber) {
		const { data } = overview;

		if (pageNumber === "") {
			data.metadata.pagination.page = 1;
		} else if (pageNumber <= 1) {
			data.metadata.pagination.page = 1;
		} else if (pageNumber > overview.data.metadata.pagination.page_count) {
			data.metadata.pagination.page = data.metadata.pagination.page_count;
		} else {
			data.metadata.pagination.page = pageNumber;
		}

		overview.setData(data);
	}

	goToNextPage(overview) {
		if (overview.data.metadata.pagination.page_count <= overview.data.metadata.pagination.page) {
			throw "Overview does not have a next page.";
		}

		const { data } = overview;
		data.metadata.pagination.page = data.metadata.pagination.page + 1;
		overview.setData(data);
	}

	goToLastPage(overview) {
		if (overview.data.metadata.pagination.page_count <= overview.data.metadata.pagination.page) {
			throw "Overview does not have a first page.";
		}

		const { data } = overview;
		data.metadata.pagination.page = overview.data.metadata.pagination.page_count;
		overview.setData(data);
	}

	renderAs(pagination) {
		if (this.props.renderAs) {
			return this.props.renderAs(pagination);
		}

		return this.renderOuter(pagination);
	}

	renderOuter(pagination) {
		if (this.props.renderOuter) {
			return this.props.renderOuter(pagination);
		}

		return (
			<div className="overview-pager " role="navigation" aria-label="Pagination">
				{pagination.component.renderInner(pagination)}
			</div>
		);
	}

	renderInner(pagination) {
		if (this.props.renderInner) {
			return this.props.renderInner(pagination);
		}

		return (
			<Fragment>
				{this.renderFirst(pagination)}
				{this.renderPrevious(pagination)}
				{this.renderCurrentPageNumber(pagination)}
				{this.renderNext(pagination)}
				{this.renderLast(pagination)}
			</Fragment>
		);
	}

	renderFirst(pagination) {
		const parameters = {};
		parameters.goToFirstPage = pagination.goToFirstPage;
		parameters.enabled = pagination.hasPreviousPage;

		if (this.props.renderFirst) {
			return this.props.renderFirst(pagination, parameters);
		}

		if (parameters.enabled) {
			return (
				<button className="overview-pager-first" onClick={parameters.goToFirstPage}>
					<EvaIcon type="arrowhead-left" fill="#ffffff" height="15" width="15" />
				</button>
			);
		} else {
			return (
				<button className="disabled overview-pager-first">
					<EvaIcon type="arrowhead-left" fill="#ffffff" height="15" width="15" />
				</button>
			);
		}
	}

	renderPrevious(pagination) {
		const parameters = {};
		parameters.goToPreviousPage = pagination.goToPreviousPage;
		parameters.enabled = pagination.hasPreviousPage;

		if (this.props.renderPrevious) {
			return this.props.renderPrevious(pagination, parameters);
		}

		if (parameters.enabled) {
			return (
				<button className="overview-pager-previous " onClick={parameters.goToPreviousPage}>
					<EvaIcon type="arrow-ios-back" fill="#ffffff" height="15" width="15" />
				</button>
			);
		} else {
			return (
				<button className="disabled overview-pager-previous">
					<EvaIcon type="arrow-ios-back" fill="#ffffff" height="15" width="15" />
				</button>
			);
		}
	}

	renderCurrentPageNumber(pagination) {
		const parameters = {};
		parameters.goToPage = pagination.goToPage;
		parameters.pageNumber = pagination.currentPage;
		parameters.enabled = true;

		if (this.props.renderPageNumber) {
			return this.props.renderPageNumber(pagination, parameters);
		}

		return (
			<input
				key={parameters.pageNumber}
				type="number"
				className="overview-pager-input"
				defaultValue={pagination.currentPage}
				onChange={e => this.handlePaginationChange(pagination.overview, e)}
			/>
		);
	}

	renderNext(pagination) {
		const parameters = {};
		parameters.goToNextPage = pagination.goToNextPage;
		parameters.enabled = pagination.hasNextPage;

		if (this.props.renderNext) {
			return this.props.renderNext(pagination, parameters);
		}

		if (parameters.enabled) {
			return (
				<button className="overview-pager-next" onClick={parameters.goToNextPage}>
					<EvaIcon type="arrow-ios-forward" fill="#ffffff" height="15" width="15" />
				</button>
			);
		} else {
			return (
				<button className="disabled overview-pager-next">
					<EvaIcon type="arrow-ios-forward" fill="#ffffff" height="15" width="15" />
				</button>
			);
		}
	}

	renderLast(pagination) {
		const parameters = {};
		parameters.goToLastPage = pagination.goToLastPage;
		parameters.enabled = pagination.hasNextPage;

		if (this.props.renderLast) {
			return this.props.renderLast(pagination, parameters);
		}

		if (parameters.enabled) {
			return (
				<button className="overview-pager-last" onClick={parameters.goToLastPage}>
					<EvaIcon type="arrowhead-right" fill="#ffffff" height="15" width="15" />
				</button>
			);
		} else {
			return (
				<button className="disabled overview-pager-last">
					<EvaIcon type="arrowhead-right" fill="#ffffff" height="15" width="15" />
				</button>
			);
		}
	}

	render() {
		return (
			<OverviewContext.Consumer>
				{overview => this.renderAs(this.calculatePaginationState(overview))}
			</OverviewContext.Consumer>
		);
	}
}

export default Pagination;

Pagination.propTypes = {
	// Bootstrap
	renderAs: PropTypes.func,
	renderOuter: PropTypes.func,
	renderInner: PropTypes.func,

	// Components
	renderFirst: PropTypes.func,
	renderPrevious: PropTypes.func,
	renderNext: PropTypes.func,
	renderLast: PropTypes.func,

	// Abstractions
	renderPageNumber: PropTypes.func,
};

Pagination.defaultProps = {
	size: 5,
};
