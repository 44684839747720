import PropTypes from "prop-types";
import { uctrans } from "../../../lib/Translator";
import ConfirmDialog from "./ConfirmDialog";

export default function DeleteDialog(props) {
	return (
		props.deleteData && (
			<ConfirmDialog
				title={props.deleteData.message}
				className="delete_dialogs"
				confirmButtonLabel={uctrans("general.confirm")}
				cancelButtonLabel={uctrans("general.cancel")}
				confirmIsDisabled={props.deleteData.errors.length > 0}
				isOpen={props.isOpen}
				onCancel={props.onCancel}
				onConfirm={props.onConfirm}>
				{props.deleteData.errors.length > 0 && (
					<ul>
						{props.deleteData.errors.map((error, key) => (
							<li key={key}>
								{error.url ? (
									<a className="link" href={error.url}>
										{error.text}
									</a>
								) : (
									error.text
								)}
							</li>
						))}
					</ul>
				)}
				{props.deleteData.warnings.length > 0 && (
					<>
						<br />
						<ul>
							{props.deleteData.warnings.map((warning, key) => (
								<li key={key}>
									{warning.url ? (
										<a className="link" href={warning.url}>
											{warning.text}
										</a>
									) : (
										warning.text
									)}
								</li>
							))}
						</ul>
					</>
				)}
			</ConfirmDialog>
		)
	);
}

DeleteDialog.propTypes = {
	deleteData: PropTypes.shape({
		errors: PropTypes.array.isRequired,
		warnings: PropTypes.array.isRequired,
		message: PropTypes.string,
	}).isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	isOpen: PropTypes.bool,
};
