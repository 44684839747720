import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import DebouncedTextarea from "./DebouncedTextarea";

export default function AutosizeTextarea(props) {
	const textareReference = useRef(null);
	useEffect(() => {
		textareReference.current.style.height = "inherit";

		let height = textareReference.current.scrollHeight + 10;
		if (height > props.maxHeight) {
			height = props.maxHeight;
		}
		textareReference.current.style.height = "inherit";
		textareReference.current.style.height = `${height}px`;
	});

	return (
		<DebouncedTextarea
			reference={textareReference}
			id={typeof props.id === "undefined" ? props.name : props.id}
			name={props.name}
			value={props.value || ""}
			onChange={props.onChange}
			disabled={props.disabled}
			autoComplete={props.autoComplete}
			maxLength={props.maxLength}
			debounceTime={100}
			placeholder={props.placeholder}
		/>
	);
}

AutosizeTextarea.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	autoComplete: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	id: PropTypes.string,
	maxLength: PropTypes.number,
	maxHeight: PropTypes.number,
	placeholder: PropTypes.string,
};

AutosizeTextarea.defaultProps = {
	autoComplete: false,
	disabled: false,
	showMaxLengthCounter: false,
	maxHeight: 500,
};
