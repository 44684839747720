import assign from "lodash/assign";
import * as PropTypes from "prop-types";
import Nav from "../../../../lib/Nav";
import { uctrans } from "../../../../lib/Translator";
import IconButton from "../IconButton";
import { OverviewContext } from "./overview-context";

export default function OverviewExport(props) {
	const buildUrl = (url, parameters) => {
		if (props.extraQuerystringParameters) {
			parameters = assign(parameters, props.extraQuerystringParameters);
		}

		const searchParams = new URLSearchParams(parameters);

		return `${url}?${searchParams.toString()}`;
	};

	const openExport = overview => () => {
		const url = buildUrl(props.exportUrls.csv, overview.getParameters());

		Nav.go(url, true);
	};

	const renderAs = overview => (
		<IconButton
			className="overview-export"
			onClick={openExport(overview)}
			content={uctrans("general.export")}
			icon="download-outline"
			primary
		/>
	);

	return <OverviewContext.Consumer>{overview => renderAs(overview)}</OverviewContext.Consumer>;
}
OverviewExport.propTypes = {
	extraQuerystringParameters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	exportUrls: PropTypes.shape({
		csv: PropTypes.string,
	}),
};
