import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import LabeledTextInput from "../general/form/LabeledTextInput";
import UpperCaseTextInput from "../general/form/UpperCaseTextInput";
import ZipCodeTextInput from "../general/form/ZipCodeTextInput";

export default function AddressForm(props) {
	const [addressFormData, setFormData] = useState(props.addressFormData);
	const [useAddition, setUseAddition] = useState(props.useAddition);
	const [useCountry, setUseCountry] = useState(props.useCountry);
	const [useCopyButton] = useState(props.useCopyButton);

	useEffect(() => {
		setFormData(props.addressFormData);
	}, [props.addressFormData]);

	const onChange = event => {
		const { target } = event;
		const value = target.type === "checkbox" ? target.checked : target.value;

		const newaddressFormData = { ...addressFormData };
		newaddressFormData[target.name] = value;
		setFormData(newaddressFormData);
		props.onChange(newaddressFormData);
	};

	useEffect(() => {
		setUseAddition(props.useAddition);
	}, [props.useAddition]);
	useEffect(() => {
		setUseCountry(props.useCountry);
	}, [props.useCountry]);
	return (
		<>
			<div className="form-full">
				<div className="form-2-3">
					<LabeledTextInput
						disabled={props.disabled}
						name="street"
						label="addresses.fields.street"
						value={props.addressFormData && props.addressFormData.street ? props.addressFormData.street : ""}
						onChange={onChange}
						required={props.required}
					/>
				</div>
				<div className="form-1-3">
					{useAddition ? (
						<div className="flex">
							<div>
								<LabeledTextInput
									disabled={props.disabled}
									name="number"
									label="addresses.fields.number"
									value={props.addressFormData && props.addressFormData.number ? props.addressFormData.number : ""}
									onChange={onChange}
									required={props.required}
								/>
							</div>

							<div className="ml-3">
								<LabeledTextInput
									disabled={props.disabled}
									name="addition"
									label="addresses.fields.addition"
									value={props.addressFormData && props.addressFormData.addition ? props.addressFormData.addition : ""}
									onChange={onChange}
								/>
							</div>
						</div>
					) : (
						<LabeledTextInput
							disabled={props.disabled}
							name="number"
							label="addresses.fields.number"
							value={props.addressFormData && props.addressFormData.number ? props.addressFormData.number : ""}
							onChange={onChange}
							required={props.required}
						/>
					)}
				</div>
			</div>
			<div className="form-full">
				<div className="form-1-3">
					<ZipCodeTextInput
						disabled={props.disabled}
						name="zipcode"
						label="addresses.fields.zipcode"
						value={props.addressFormData && props.addressFormData.zipcode ? props.addressFormData.zipcode : ""}
						onChange={onChange}
						required={props.required}
					/>
				</div>
				<div className="form-2-3">
					<UpperCaseTextInput
						disabled={props.disabled}
						name="city"
						label="addresses.fields.city"
						value={props.addressFormData && props.addressFormData.city ? props.addressFormData.city : ""}
						onChange={onChange}
						required={props.required}
					/>
				</div>
				{useCopyButton && (
					<>
						<br />
						<a className="button button-tertiary mb-5" onClick={() => props.onCopy(props.addressFormData)}>
							{props.copyLabel}
						</a>
					</>
				)}
			</div>
			{!!useCountry && (
				<div className="form-full">
					<div className="form-2-3">
						<LabeledTextInput
							disabled={props.disabled}
							name="country"
							label="addresses.fields.country"
							value={props.addressFormData && props.addressFormData.country ? props.addressFormData.country : ""}
							onChange={onChange}
							required={props.required}
						/>
					</div>
				</div>
			)}
		</>
	);
}

AddressForm.propTypes = {
	addressFormData: PropTypes.object,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onCopy: PropTypes.func,
	required: PropTypes.bool,
	useAddition: PropTypes.bool,
	useCountry: PropTypes.bool,
	useCopyButton: PropTypes.bool,
	copyLabel: PropTypes.string,
};

AddressForm.defaultProps = {
	AddressForm: PropTypes.bool,
	useAddition: false,
	useCountry: false,
	useCopyButton: false,
};
