import { Component } from "react";
import { OverviewContext } from "./overview-context";

class Filter extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(context, event) {
		context.filterChange(event);
	}

	renderAs() {
		return "";
	}

	render() {
		return <OverviewContext.Consumer>{context => this.renderAs(context)}</OverviewContext.Consumer>;
	}
}
export default Filter;
Filter.displayName = "Filter";
