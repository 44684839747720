import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import EvaIcon from "./EvaIcon";

let cleanupTimeOut = null;

export default function Dialog(props) {
	const [isOpen, setIsOpen] = useState(props.isOpen);
	const [initialized, setInitialized] = useState(props.isOpen);

	const closeDialog = () => {
		setIsOpen(false);

		if (props.onClose) {
			// This timeout is used to wait for the fade out animation
			props.onClose();
		}

		// clean up after a while
		cleanupTimeOut = setTimeout(() => {
			setInitialized(null);
		}, 300);
	};

	/**
	 * Remove timeout on unmount
	 */
	useEffect(() => () => clearTimeout(cleanupTimeOut), []);

	/**
	 * This useEffect makes sure that the state gets updated after changing the prop
	 */
	useEffect(
		() => {
			setIsOpen(props.isOpen);

			if (!initialized && props.isOpen) {
				setInitialized(props.isOpen);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.isOpen],
	);

	if (!initialized) {
		return null;
	}

	const customStyles = {};

	if (props.darkBackground) {
		customStyles.overlay = {
			background: "rgba(0,0,0,.25)",
		};

		customStyles.closeButton = {
			color: "white",
			background: "#555555",
			height: "30px",
			width: "30px",
			borderRadius: "100%",
		};
	}

	if (props.isFullscreen) {
		customStyles.content = {
			...customStyles.content,
			top: "3vw",
			bottom: "3vw",
			left: "3vw",
			right: "3vw",
		};
	}

	if (props.transparentContent) {
		customStyles.content = {
			...customStyles.content,
			background: "transparent",
			boxShadow: "none",
		};
	}

	if (props.width && !props.isFullscreen) {
		customStyles.content = {
			...customStyles.content,
			width: props.width,
		};
	}

	return (
		<ReactModal
			isOpen={isOpen}
			contentLabel="Modal"
			className={`IAS_Modal_Inner ${props.className || ""}`}
			style={customStyles}
			onRequestClose={(!props.isModal && closeDialog) || null}
			overlayClassName={`IAS_Modal_Overlay ${props.className || ""}`}
			shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
			ariaHideApp={false}
			closeTimeoutMS={350}>
			<div>
				<div className="content">
					<h4>{props.title}</h4>
					{props.children}
				</div>

				{!props.isModal && (
					<button className="close_button" style={customStyles.closeButton} onClick={closeDialog}>
						<EvaIcon className="float-right" type="close-outline" fill="#009286" height="20" width="20" />
					</button>
				)}
			</div>
		</ReactModal>
	);
}

Dialog.propTypes = {
	children: PropTypes.node.isRequired,
	isOpen: PropTypes.bool.isRequired,
	isModal: PropTypes.bool,
	isFullscreen: PropTypes.bool,

	shouldCloseOnOverlayClick: PropTypes.bool,

	title: PropTypes.string,
	className: PropTypes.string,

	darkBackground: PropTypes.bool,
	transparentContent: PropTypes.bool,

	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
};

Dialog.defaultProps = {
	width: 600,
	shouldCloseOnOverlayClick: true,
	isFullScreen: false,
};
