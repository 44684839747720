import PropTypes from "prop-types";
import { uctrans } from "../../../../lib/Translator";
import findSelectedOption from "../../../hooks/findSelectedOption";
import Filter from "./Filter";
import Select from "../Select";
import classNames from "classnames";

class SelectFilter extends Filter {
	handleChange(context, value) {
		super.handleChange(context, {
			target: {
				value,
				type: "select",
				name: this.props.name,
				className: this.props.className,
			},
		});
	}

	renderAs(context) {
		const value = Object.prototype.hasOwnProperty.call(context.data.metadata.filtering.filters, this.props.name)
			? context.data.metadata.filtering.filters[this.props.name]
			: null;
		const selectedOption = findSelectedOption(this.props.options, value, this.props.defaultSelectFirstOption);
		const className = classNames("overview-filter filter-select", this.props.className);

		return (
			<div className={className}>
				<label htmlFor="">{this.props.label}</label>
				<Select
					options={this.props.options}
					value={selectedOption}
					name={this.props.name}
					onChange={option => {
						if (this.props.handleChange) {
							this.props.handleChange(option.value);
						} else {
							this.handleChange(context, option.value);
						}
					}}
					isClearable={false}
				/>
			</div>
		);
	}
}

export default SelectFilter;
SelectFilter.propTypes = {
	placeholder: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	defaultSelectFirstOption: PropTypes.bool,
	className: PropTypes.string,
	label: PropTypes.string,
	handleChange: PropTypes.func,
};

SelectFilter.defaultProps = {
	placeholder: uctrans("general.select"),
	defaultSelectFirstOption: true,
};
