import PropTypes from "prop-types";
import { uctrans } from "../../../lib/Translator";
import LeadManageItem from "./LeadManageItem";

export default function ClientConsentManage({ clientConsent, forWebsite, onChange, useOverviewContext }) {
	const customerCardUrl = forWebsite && window.data.customer_card_url ? window.data.customer_card_url : null;

	return (
		!!clientConsent &&
		clientConsent.active && (
			<div className="text-xs">
				<div className="bg-primary-lightest p-4 flex justify-between">
					<div className="pr-2">
						<label>{uctrans("client_consents.fields.client_name")}</label>
						<p>{clientConsent.name_first_applicant}</p>
						{!!clientConsent.name_second_applicant && <p>{clientConsent.name_second_applicant}</p>}
					</div>
					<div className="pr-2">
						<label>{uctrans("client_consents.fields.loan_number")}</label>
						<p>{clientConsent.loan_number}</p>
					</div>
					{forWebsite && (
						<a
							className="button button-primary text-xs button-adjustment"
							href={`${customerCardUrl}${clientConsent.loan_number}`}>
							{uctrans("client_consents.to_loan")}
						</a>
					)}
				</div>

				{clientConsent.leads.map((lead, i) => (
					<LeadManageItem
						key={i}
						lead={lead}
						forWebsite={forWebsite}
						onChange={() => {
							if (onChange) {
								onChange(clientConsent);
							}
						}}
						useOverviewContext={useOverviewContext}
					/>
				))}
			</div>
		)
	);
}

ClientConsentManage.propTypes = {
	clientConsent: PropTypes.shape({
		id: PropTypes.number,
		loan_number: PropTypes.number,
		energylabel: PropTypes.string,
		name_first_applicant: PropTypes.string,
		name_second_applicant: PropTypes.string,
		active: PropTypes.bool,
		leads: PropTypes.array,
	}),
	forWebsite: PropTypes.bool,
	onChange: PropTypes.func,
	useOverviewContext: PropTypes.bool,
};

ClientConsentManage.defaultProps = {
	forWebsite: true,
	useOverviewContext: true,
};
