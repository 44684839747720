import forEach from "lodash/forEach";
import { createLoader } from "../notifications";
import axios from "axios";
import PropTypes from "prop-types";
import { Component } from "react";
import { uctrans } from "../../../../lib/Translator";
import { OverviewContext } from "./overview-context";
import { route } from "../../../../helpers";

class Overview extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: props.initialData,
			loading: false,
			component: this,
			dialogOpen: false,
			selectedItem: null,
			deleteData: {
				errors: [],
				warnings: [],
			},
			useDragAndDrop: props.useDragAndDrop,
			toggleDeleteDialog: () => {
				this.setState({
					dialogOpen: !this.state.dialogOpen,
				});
			},
			setData: (data, shouldLoadData = true) => {
				this.setState({
					data,
				});

				if (shouldLoadData) {
					this.loadData();
				}
			},
			loadData: () => {
				this.loadData();
			},
			askDelete: async (selectedItem, customDeleteLink = null) => {
				const deleteLink = customDeleteLink
					? customDeleteLink
					: props.deleteRouteName
					  ? route(props.deleteRouteName, selectedItem.id)
					  : selectedItem.delete_link;

				const result = await axios.get(deleteLink);

				if (result.status === 200) {
					this.setState({ selectedItem, deleteData: result.data });
					this.state.toggleDeleteDialog();
				}
			},
			submitDelete: async () => {
				const loader = createLoader(
					uctrans("general.:item_is_being_deleted", { item: this.props.modelTranslations.singular }),
				);

				try {
					await axios.post(this.state.deleteData.destroy_link, { _method: "delete" });
					loader.success(uctrans("general.deleted_:item", { item: this.props.modelTranslations.singular }));
					this.state.toggleDeleteDialog();
					this.loadData();
				} catch (exception) {
					loader.failure(exception.message);
				}
			},
			filterChange: e => {
				const { target } = e;

				const value = target.type === "checkbox" ? (target.checked ? 1 : 0) : target.value;
				if (target.name) {
					const data = { ...this.state.data };

					if (Object.prototype.hasOwnProperty.call(data.metadata.filtering.defaults, target.name)) {
						data.metadata.filtering.filters[target.name] = value === null ? "" : value;
					} else if (value === null || value === "") {
						delete data.metadata.filtering.filters[target.name];
					} else {
						data.metadata.filtering.filters[target.name] = value;
					}
					data.metadata.pagination.page = 1;

					this.state.setData(data);
				}
			},
			dndChange: items => {
				const data = { ...this.state.data };

				data.items = items;

				this.state.setData(data, false);
			},
			getParameters: () => this.parameters,
			getActiveFilterCount: () => this.activeFilterCount,
		};

		this.lastRequest = 0;
	}

	componentDidUpdate(prevProps) {
		if (this.props.dataUpdatedAt !== null && prevProps.dataUpdatedAt !== this.props.dataUpdatedAt) {
			this.loadData();
		}
	}
	async loadData() {
		const thisRequest = Math.round(new Date());
		this.lastRequest = thisRequest;

		this.setState({
			loading: true,
		});

		const data = await this.props.dataSource(this);

		if (this.lastRequest !== thisRequest) {
			return;
		}

		this.setState({
			loading: false,
			data: {
				...this.state.data,
				items: data.items,
				metadata: data.metadata,
			},
		});
	}

	getConfig(key, value) {
		if (key instanceof Function) {
			key = key.name;
		}

		if (!Object.prototype.hasOwnProperty.call(this.props.config, key)) {
			return null;
		}

		const subConfig = this.props.config[key];
		if (!Object.prototype.hasOwnProperty.call(subConfig, value)) {
			return null;
		}

		return subConfig[value];
	}

	get parameters() {
		const { metadata } = this.state.data;

		const parameters = {};

		const { filters } = metadata.filtering;
		const filterNames = Object.keys(filters);
		filterNames.forEach(filterName => {
			if (filters[filterName] !== null) {
				parameters[`filters[${filterName}]`] = filters[filterName];
			}
		});

		parameters.page = metadata.pagination.page;
		parameters.limit = metadata.pagination.limit;

		if (metadata.sorting.active_column) {
			let sortString = metadata.sorting.active_column;
			if (metadata.sorting.active_order) {
				sortString += `:${metadata.sorting.active_order}`;
			}
			parameters.sort = sortString;
		}

		if (metadata.filtering.showCollapsible) {
			parameters.showCollapsible = 1;
		}

		return parameters;
	}

	get activeFilterCount() {
		let cnt = 0;
		forEach(this.state.data.metadata.filtering.filters, (value, key) => {
			if (Object.prototype.hasOwnProperty.call(this.state.data.metadata.filtering.defaults, key)) {
				if (value === null || this.state.data.metadata.filtering.defaults[key].toString() !== value.toString()) {
					cnt++;
				}
			} else if (value !== null) {
				cnt++;
			}
		});
		forEach(this.state.data.metadata.filtering.defaults, (value, key) => {
			if (!Object.prototype.hasOwnProperty.call(this.state.data.metadata.filtering.defaults, key)) {
				cnt++;
			}
		});

		return cnt;
	}

	render() {
		return (
			<OverviewContext.Provider
				value={{
					data: this.state.data,
					loading: this.state.loading,
					component: this.state.component,
					dialogOpen: this.state.dialogOpen,
					selectedItem: this.state.selectedItem,
					deleteData: this.state.deleteData,
					useDragAndDrop: this.state.useDragAndDrop,
					toggleDeleteDialog: this.state.toggleDeleteDialog,
					setData: this.state.setData,
					loadData: this.state.loadData,
					askDelete: this.state.askDelete,
					submitDelete: this.state.submitDelete,
					filterChange: this.state.filterChange,
					dndChange: this.state.dndChange,
					getParameters: this.state.getParameters,
					getActiveFilterCount: this.state.getActiveFilterCount,
				}}>
				{this.props.children}
			</OverviewContext.Provider>
		);
	}
}

export default Overview;

Overview.propTypes = {
	children: PropTypes.node.isRequired,
	initialData: PropTypes.object.isRequired,
	dataSource: PropTypes.func.isRequired,
	config: PropTypes.object,
	deleteRouteName: PropTypes.string,
	modelTranslations: PropTypes.object,
	useDragAndDrop: PropTypes.bool,
	dataUpdatedAt: PropTypes.number,
};

Overview.defaultProps = {
	config: {},
	dataUpdatedAt: null,
};
