import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import InterestCheckmark from "./InterestCheckmark";

export default function SelectInterestList(props) {
	const [selectedInterests, setSelectedInterests] = useState(props.initialInteresttypes);

	useEffect(() => {
		props.onChange(selectedInterests);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInterests]);

	const selectItem = interest => [...selectedInterests, interest];

	const unSelectItem = value => {
		const newSelectedInterests = [...selectedInterests];
		const index = newSelectedInterests.indexOf(value);
		if (index !== -1) {
			newSelectedInterests.splice(index, 1);
			return newSelectedInterests;
		}
		return newSelectedInterests;
	};

	const getInterest = interest => {
		const compareInterest = props.initialInteresttypes.findIndex(i => i.id === interest.id);

		if (compareInterest !== -1) {
			return selectedInterests[compareInterest];
		}

		return interest;
	};

	const interestChangeHandler = interest => {
		const interests = selectedInterests.indexOf(interest) !== -1 ? unSelectItem(interest) : selectItem(interest);
		setSelectedInterests(interests);
	};

	const textualChangeHandler = interest => {
		const compareInterest = selectedInterests.findIndex(i => i.id === interest.id);

		if (compareInterest !== -1) {
			if (selectedInterests[compareInterest].explanation !== interest.explanation) {
				const newSelectedInterests = unSelectItem(selectedInterests[compareInterest]);

				setSelectedInterests([...newSelectedInterests, interest]);
			}
		}
	};

	return props.initialCategories.map(category => (
		<div key={category.id}>
			<label htmlFor={category.name}>{category.name}</label>
			<ul className="mb-6">
				{category.interestTypes.map(interest => (
					<InterestCheckmark
						disabled={props.disabled}
						key={interest.id}
						checked={props.initialInteresttypes.map(e => Number(e.id)).indexOf(Number(interest.id)) !== -1}
						interest={getInterest(interest)}
						onChange={interestChangeHandler}
						textualChange={textualChangeHandler}
					/>
				))}
			</ul>
		</div>
	));
}

SelectInterestList.proptypes = {
	initialInteresttypes: PropTypes.array,
	initialCategories: PropTypes.array,
	disabled: PropTypes.bool,
};

SelectInterestList.defaultProps = {
	disabled: false,
};
