export default function useSelectHandler(setState) {
	return (selectedValue, metaData) => {
		let newValue = null;

		if (selectedValue !== null) {
			newValue = selectedValue.value;
		} else {
			newValue = "";
		}

		const { name } = metaData;

		setState(state => ({
			...state,
			[name]: newValue,
		}));
	};
}
