import { useContext } from "react";
import PropTypes from "prop-types";
import QuestionnaireContext from "../QuestionnaireContext";

export default function NumericQuestion(props) {
	const { setAnswer, replaceTags } = useContext(QuestionnaireContext);

	const handleSetAnswer = event => {
		setAnswer(props.question, event.target.value);
	};

	return (
		<div className="container flex flex-col-reverse md:flex-row">
			<span className="form-full">
				<label>
					<span className="question-title" dangerouslySetInnerHTML={{ __html: replaceTags(props.question.content) }} />{" "}
					{props.question.required && <span className="text-orange">*</span>}
				</label>
				<input
					disabled={props.disabled}
					type="number"
					name={`question-numeric-${props.question.id}`}
					placeholder={props.question.placeholder}
					value={props.numeric_open_content}
					onChange={handleSetAnswer}
				/>
			</span>
		</div>
	);
}

NumericQuestion.propTypes = {
	question: PropTypes.object,
	numeric_open_content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	disabled: PropTypes.bool,
};

NumericQuestion.defaultProps = {
	disabled: false,
	numeric_open_content: "",
};
