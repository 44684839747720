import PropTypes from "prop-types";
import { Component } from "react";

class DebouncedInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value,
			bouncing: false,
		};

		this.timeoutIdentifier = null;

		this.handleChange = this.handleChange.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.bouncing) {
			return prevState;
		} else {
			return {
				...nextProps,
				bouncing: false,
			};
		}
	}

	handleChange(event) {
		this.setState({
			value: event.target.value,
			bouncing: true,
		});

		const copiedEvent = Object.assign({}, event);

		if (this.timeoutIdentifier) {
			clearTimeout(this.timeoutIdentifier);
			this.timeoutIdentifier = null;
		}

		this.timeoutIdentifier = setTimeout(() => {
			if (this.props.onChange) {
				this.props.onChange(copiedEvent);
			}

			this.setState({
				bouncing: false,
			});
		}, this.props.debounceTime);
	}

	render() {
		return (
			<div>
				<input
					name={this.props.name}
					placeholder={this.props.placeholder}
					className={this.props.className}
					autoComplete={this.props.autocomplete ? "on" : "off"}
					type={this.props.type}
					value={this.state.value}
					onChange={this.handleChange}
					step={this.props.step}
					disabled={this.props.disabled}
					autoFocus={this.props.autoFocus}
				/>
			</div>
		);
	}
}

export default DebouncedInput;

DebouncedInput.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	debounceTime: PropTypes.number,
	autocomplete: PropTypes.bool,
	type: PropTypes.string,
	step: PropTypes.string,
	disabled: PropTypes.bool,
	autoFocus: PropTypes.bool,
};

DebouncedInput.defaultProps = {
	debounceTime: 400,
	autocomplete: true,
	disabled: false,
	type: "text",
	step: "any",
	autoFocus: false,
};
