export default function findSelectedOption(options, value, defaultSelectFirstOption = false) {
	const finder = (options, value, getFirstAvailable) => {
		let selectedOption = null;
		options.forEach(option => {
			if (selectedOption === null) {
				// Group select
				if (option.options) {
					selectedOption = findSelectedOption(option.options, value, getFirstAvailable);
					return;
				}
				if (option.value === null && value === null) {
					selectedOption = option;
					return;
				}
				if (option.value !== null && value !== null && option.value.toString() === value.toString()) {
					selectedOption = option;
					return;
				}
				if (getFirstAvailable) {
					selectedOption = option;
				}
			}
		});
		return selectedOption;
	};

	let selectedOption = finder(options, value, false);
	if (selectedOption == null && defaultSelectFirstOption) {
		selectedOption = finder(options, value, true);
	}
	return selectedOption;
}
