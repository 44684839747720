import { createLoader } from "../notifications";
import axios from "axios";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import { uctrans } from "../../../../lib/Translator";
import { OverviewContext } from "./overview-context";
import EvaIcon from "../EvaIcon";
import DefaultSortIcon from "../../../../../static/img/icons/arrow-sort.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { route } from "../../../../../js/helpers";

class DataTable extends Component {
	constructor(props) {
		super(props);
		const columnComponents = this.getColumnComponents();

		let totalWidth = 0;
		const widthPerColumn = [];
		columnComponents.forEach((column, i) => {
			totalWidth += column.props.width;
			widthPerColumn[i] = column.props.width;
		});

		let totalGridWidth = 0;
		const gridWidthPerColum = widthPerColumn.map((width, i) => {
			let calculatedGridWidth;
			if (i === widthPerColumn.length - 1) {
				calculatedGridWidth = 24 - totalGridWidth;
			} else {
				calculatedGridWidth = Math.floor(width * (24 / totalWidth));
				if (calculatedGridWidth === 0) {
					calculatedGridWidth = 1;
				}
			}
			totalGridWidth += calculatedGridWidth;
			return calculatedGridWidth;
		});

		this.state = {
			columnComponents,
			gridWidthPerColum,
		};

		this.onDragEnd = this.onDragEnd.bind(this);
	}

	renderHeader(overview, column) {
		if (column.props.renderHeader) {
			return column.props.renderHeader();
		} else if (this.props.renderHeader) {
			return this.props.renderHeader(column);
		} else {
			if (!column.props.sortable) {
				return column.props.renderHeaderContent();
			}

			const activeSortingColumn = overview.data.metadata.sorting.active_column;

			let relevantSortingOrder = null;
			if (column.props.sortable === activeSortingColumn) {
				relevantSortingOrder = overview.data.metadata.sorting.active_order;
			}

			let sortingIcon = "";
			switch (relevantSortingOrder) {
				case "asc":
					sortingIcon = "arrow-down";
					break;

				case "desc":
					sortingIcon = "arrow-up";
					break;

				default:
					sortingIcon = DefaultSortIcon;
					break;
			}

			return overview.useDragAndDrop ? (
				<span>{column.props.renderHeaderContent()}</span>
			) : (
				<span>
					<a>
						{column.props.renderHeaderContent()}
						{relevantSortingOrder === "asc" || relevantSortingOrder === "desc" ? (
							<EvaIcon className="inline-block ml-1" type={sortingIcon} fill="#ffffff" height="12" width="12" />
						) : (
							<img className="inline-block ml-1" width={12} src={DefaultSortIcon} />
						)}
					</a>
				</span>
			);
		}
	}

	static headerClick(overview, column) {
		if (!column.props.sortable) {
			return;
		}
		const sortingOrder =
			overview.data.metadata.sorting.active_column === column.props.sortable
				? overview.data.metadata.sorting.active_order
				: null;

		let newSortingOrder = null;
		let newSortingColumn = null;

		switch (sortingOrder) {
			case "asc":
				newSortingOrder = "desc";
				newSortingColumn = column.props.sortable;
				break;

			case "desc":
				newSortingOrder = null;
				newSortingColumn = null;
				break;

			default:
				newSortingOrder = "asc";
				newSortingColumn = column.props.sortable;
				break;
		}

		const { data } = overview;

		data.metadata.pagination.page = 1;
		data.metadata.sorting.active_column = newSortingColumn;
		data.metadata.sorting.active_order = newSortingOrder;

		overview.setData(data);
	}

	static renderCell(overview, column, item) {
		return column.props.renderCellContent(item, overview);
	}

	render() {
		return (
			<OverviewContext.Consumer>
				{overview =>
					this.renderAs({
						overview,
						component: this,
						columnComponents: this.state.columnComponents,
						gridWidthPerColum: this.state.gridWidthPerColum,
					})
				}
			</OverviewContext.Consumer>
		);
	}

	renderAs(dataTable) {
		return this.renderOuter(dataTable);
	}

	renderOuter(dataTable) {
		const parameters = {};
		parameters.renderInner = () => this.renderInner(dataTable);

		if (this.props.renderOuter) {
			return this.props.renderOuter(dataTable);
		} else if (dataTable.overview.component.getConfig("DataTable", "renderOuter")) {
			return dataTable.overview.component.getConfig("DataTable", "renderOuter")(dataTable, parameters);
		}

		return <div>{parameters.renderInner()}</div>;
	}

	getItemStyle(isDragging, draggableStyle) {
		return {
			// some basic styles to make the items look a bit nicer
			userSelect: "none",

			// change background colour if dragging
			background: isDragging ? "#FFFFFF" : "",

			// styles we need to apply on draggables
			...draggableStyle,
		};
	}

	getListStyle(isDraggingOver) {
		return {
			background: isDraggingOver ? "#D7F0EE" : "lightgrey",
		};
	}

	getClassNames(isDragging) {
		if (isDragging) {
			return "dragging-active";
		}

		return "dragging-inactive";
	}

	async onDragEnd(dragEvent, overview) {
		if (dragEvent.destination && dragEvent.source.index !== dragEvent.destination.index) {
			overview.dndChange(this.reorder(overview.data.items, dragEvent.source.index, dragEvent.destination.index));

			overview.data.items.splice(
				dragEvent.destination.index,
				0,
				overview.data.items.splice(dragEvent.source.index, 1)[0],
			);

			const changeItem = overview.data.items.find(item => `${item.id}` === `${dragEvent.draggableId}`);
			const moveAfter =
				dragEvent.destination.index === 0 ? -1 : overview.data.items[dragEvent.destination.index - 1].id;
			const moveBefore =
				dragEvent.destination.index === overview.data.items.length - 1
					? -1
					: overview.data.items[dragEvent.destination.index + 1].id;

			// update position in DB
			const loader = createLoader(
				uctrans("general.:item_is_being_moved", { item: overview.component.props.modelTranslations.singular }),
			);

			if (typeof overview.component.props.moveRouteName !== "undefined") {
				let params = changeItem.id;
				if (typeof overview.component.props.moveRouteAdditionalParameters !== "undefined") {
					params = Object.values({ ...overview.component.props.moveRouteAdditionalParameters, params });
				}
				changeItem.move_link = route(overview.component.props.moveRouteName, params);
			}

			const result = await axios.put(changeItem.move_link, { moveAfter, moveBefore });

			if (result.status === 200) {
				loader.success(uctrans("general.moved_:item", { item: overview.component.props.modelTranslations.singular }));
				overview.loadData();
			} else {
				loader.failure("something went wrong deleting the user TODO -> show this error message");
			}
		}
	}

	reorder(list, startIndex, endIndex) {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	renderInner(dataTable) {
		if (this.props.renderInner) {
			return this.props.renderInner(dataTable);
		}

		return (
			<Fragment>
				<div className="overview-header">
					{dataTable.columnComponents.map((column, i) =>
						typeof column.props.className !== "undefined" ? (
							<div
								className={column.props.className}
								key={i}
								onClick={() => !dataTable.overview.useDragAndDrop && DataTable.headerClick(dataTable.overview, column)}>
								{this.renderHeader(dataTable.overview, column)}
							</div>
						) : (
							<div
								className={`overview-header-cell text-white w-${dataTable.gridWidthPerColum[i]}/24`}
								key={i}
								onClick={() => !dataTable.overview.useDragAndDrop && DataTable.headerClick(dataTable.overview, column)}>
								{this.renderHeader(dataTable.overview, column)}
							</div>
						),
					)}
				</div>
				{dataTable.overview.useDragAndDrop ? (
					<DragDropContext onDragEnd={result => this.onDragEnd(result, dataTable.overview)}>
						<Droppable droppableId="-1" type="-1">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={this.getListStyle(snapshot.isDraggingOver)}>
									{dataTable.overview.data.items.map((item, i) => (
										<Draggable key={item.id} draggableId={`${item.id}`} index={i}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={this.getClassNames(snapshot.isDragging)}
													style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
													<div className="overview-item" key={i}>
														{dataTable.columnComponents.map((column, j) =>
															typeof column.props.className !== "undefined" ? (
																<div className={column.props.className} key={j}>
																	{DataTable.renderCell(dataTable.overview, column, item)}
																</div>
															) : (
																<div
																	className={`overview-item-cell draggable-overview-item draggable-item w-${dataTable.gridWidthPerColum[j]}/24`}
																	key={j}>
																	{DataTable.renderCell(dataTable.overview, column, item)}
																</div>
															),
														)}
													</div>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				) : (
					dataTable.overview.data.items.map((item, i) => (
						<div className="overview-item" key={i}>
							{dataTable.columnComponents.map((column, j) => (
								<div
									className={`overview-item-cell w-${dataTable.gridWidthPerColum[j]}/24 ${
										column.props.borderLeft ? "border-l" : ""
									}`}
									key={j}>
									{DataTable.renderCell(dataTable.overview, column, item)}
								</div>
							))}
						</div>
					))
				)}
			</Fragment>
		);
	}

	getColumnComponents() {
		const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
		return children.filter(child => child && child.type.displayName === "DataColumn");
	}
}

export default DataTable;
DataTable.displayName = "DataTable";

DataTable.propTypes = {
	children: PropTypes.node.isRequired,
	renderOuter: PropTypes.func,
	renderInner: PropTypes.func,
	renderHeader: PropTypes.func,
	renderCell: PropTypes.func,
};
