import { map } from "lodash";
import PropTypes from "prop-types";
import { route } from "../../../helpers";
import { useState } from "react";
import { trans, uctrans } from "../../../lib/Translator";
import useGenericHandler from "../../hooks/useGenericHandler";
import AbnOverview from "../general/abn-overview/AbnOverview";
import Dialog from "../general/Dialog";
import EvaIcon from "../general/EvaIcon";
import Radio from "../general/form/radio/Radio";
import RadioGroup from "../general/form/radio/RadioGroup";
import IconButton from "../general/IconButton";
import DataColumn from "../general/overview/DataColumn";
import DataTable from "../general/overview/DataTable";
import Filters from "../general/overview/Filters";
import SearchBarFilter from "../general/overview/SearchBarFilter";
import Translate from "../general/Translate";

export default function ContactpersonCommunicationRequestedBySelect(props) {
	const genericHandler = useGenericHandler(props.setFormData);
	const translationKey = props.fromCrm ? "contactpersons" : "my_profile.my_collegues";

	const [addContactPersonDialogOpen, setAddContactPersonDialogOpen] = useState(false);
	const [selectedContactPerson, setSelectedContactPerson] = useState(null);

	const handleAddContactPerson = contactPerson => {
		setSelectedContactPerson(contactPerson);
		genericHandler(contactPerson ? contactPerson.id : null, "communication_creation_requested_by_contactperson_id");
		setAddContactPersonDialogOpen(false);
	};

	return props.requestedByTypeOptions ? (
		<>
			<div className="form-full mt-4">
				<div className="form-1-2">
					<label>
						<Translate
							content={
								props.creating
									? "communication.kickstarter_mail.creation_create"
									: "communication.kickstarter_mail.creation_edit"
							}
						/>
						<span className="text-orange">*</span>
					</label>
					<RadioGroup
						name="communication_creation_requested_by_type"
						value={props.formData.communication_creation_requested_by_type || 1}
						onChange={value => genericHandler(value, "communication_creation_requested_by_type")}
						horizontal={false}>
						{map(props.requestedByTypeOptions, (type, index) => (
							<Radio key={index} label={type.label} value={type.value} />
						))}
					</RadioGroup>
				</div>
			</div>
			{props.formData.communication_creation_requested_by_type === 2 && (
				<div className="form-full">
					<label htmlFor="participant_type">
						<Translate
							content="communication.kickstarter_mail.creation_:requester"
							transReplaces={{
								requester: `${translationKey}.singular`,
							}}
						/>
						<span className="text-orange">*</span>
					</label>

					{selectedContactPerson && (
						<span className="flex">
							{selectedContactPerson.name}
							<a onClick={() => handleAddContactPerson(null)}>
								<EvaIcon type="close-outline" width="18" height="18" fill="#f9acaa" className="mr-2" />
							</a>
						</span>
					)}
					{selectedContactPerson === null && (
						<>
							<IconButton
								onClick={() => {
									setAddContactPersonDialogOpen(true);
								}}
								content={uctrans("general.add_:item", {}, { item: `${translationKey}.singular` })}
								primary
							/>
							<Dialog
								width={900}
								isOpen={addContactPersonDialogOpen}
								title={uctrans("general.add_:item", {}, { item: `${translationKey}.singular` })}
								isFullScreen
								onClose={() => setAddContactPersonDialogOpen(false)}>
								<AbnOverview
									hideNrOfResults
									indexUrlAdditionalParameters={{
										company_ids: props.requestContactpersonCompanyIds,
										not_contactperson_id: props.formData.id,
									}}
									renderFilters={() => (
										<Filters>
											<SearchBarFilter type="text" name="search" />
										</Filters>
									)}
									modelTranslations={{
										plural: trans(`${translationKey}.plural`),
										singular: trans(`${translationKey}.singular`),
									}}
									indexUrl={props.fromCrm ? route("crm.contactpersons.index") : route("website.my-collegues.data")}
									renderBody={() => (
										<DataTable>
											<DataColumn
												width={6}
												renderHeaderContent={() => uctrans("client_treatment.participants.fields.companyname")}
												renderCellContent={contactPerson =>
													contactPerson.employments && contactPerson.employments.label
														? contactPerson.employments.label
														: "-"
												}
											/>
											<DataColumn
												width={5}
												renderHeaderContent={() => uctrans("general.name")}
												renderCellContent={contactPerson => (contactPerson.name ? contactPerson.name : "-")}
											/>
											<DataColumn
												width={5}
												renderHeaderContent={() => uctrans("general.email")}
												renderCellContent={contactPerson => (contactPerson.email ? contactPerson.email : "-")}
											/>
											<DataColumn
												width={1}
												renderHeaderContent={() => ""}
												renderCellContent={contactPerson => (
													<div className="overview-actions">
														<a
															onClick={() => {
																handleAddContactPerson(contactPerson);
															}}>
															<span>
																<EvaIcon type="plus-outline" fill="#009286" height="18" width="18" />
															</span>
														</a>
													</div>
												)}
											/>
										</DataTable>
									)}
								/>
							</Dialog>
						</>
					)}
				</div>
			)}
			<br />
		</>
	) : null;
}

ContactpersonCommunicationRequestedBySelect.propTypes = {
	fromCrm: PropTypes.bool,
	setFormData: PropTypes.func.isRequired,
	creating: PropTypes.bool,
	formData: PropTypes.object,
	requestedByTypeOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	requestContactpersonCompanyIds: PropTypes.array,
};

ContactpersonCommunicationRequestedBySelect.defaultProps = {
	fromCrm: true,
	creating: true,
};
