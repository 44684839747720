import classnames from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

class Checkmark extends Component {
	constructor(props) {
		super(props);

		this.handleSelectionChange = this.handleSelectionChange.bind(this);
	}

	handleSelectionChange(e) {
		if (this.props.onChange) {
			this.props.onChange(e);
		}

		if (this.props.onChecked && e.target.checked) {
			this.props.onChecked(e);
		} else if (this.props.onUnchecked && !e.target.checked) {
			this.props.onUnchecked(e);
		}
	}
	getLabel() {
		if (this.props.renderLabel) {
			return this.props.renderLabel();
		}
		return this.props.label;
	}

	render() {
		const { children } = this.props;
		const checkmarkStyle = {};

		if (this.props.hideCheckmark) {
			checkmarkStyle.display = "none";
		}
		const classNames = classnames("container-checkbox", this.props.className);

		return (
			<label className={classNames}>
				<input
					type="checkbox"
					onChange={this.handleSelectionChange}
					name={this.props.name}
					style={checkmarkStyle}
					checked={this.props.checked}
					disabled={this.props.disabled}
				/>
				<span className="checkmark" />
				{this.props.inline ? children : this.getLabel()}
				{this.props.required ? <span className="text-orange"> *</span> : ""}
			</label>
		);
	}
}

export default Checkmark;

Checkmark.propTypes = {
	children: PropTypes.node,
	checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	onChange: PropTypes.func,
	onChecked: PropTypes.func,
	onUnchecked: PropTypes.func,
	disabled: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	inline: PropTypes.bool,
	hideCheckmark: PropTypes.bool,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
	required: PropTypes.bool,
	renderLabel: PropTypes.func,
};

Checkmark.defaultProps = {
	initialChecked: false,
	required: false,
};
