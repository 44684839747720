import classnames from "classnames";
import PropTypes from "prop-types";
import EvaIcon from "./EvaIcon";

export default function IconButton(props) {
	const className = classnames(
		"button",
		props.iconBackground,
		{
			disabled: props.disabled,
			"button-primary": props.primary,
			"button-secondary-large": !props.primary,
		},
		props.className,
	);
	return (
		<a
			href={(!props.disabled && props.href) || null}
			onClick={(!props.disabled && props.onClick) || null}
			className={className}>
			<div className="flex-button">
				{props.icon && <EvaIcon type={props.icon} className="mr-2" fill={props.fill} height="16" width="16" />}
				<span className="icon-text">{props.content}</span>
			</div>
		</a>
	);
}

IconButton.propTypes = {
	href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClick: PropTypes.func,
	content: PropTypes.string,
	icon: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	primary: PropTypes.bool,
	iconBackground: PropTypes.string,
	fill: PropTypes.string,
};

IconButton.defaultProps = {
	icon: "plus",
	content: "",
	disabled: false,
	forWebsite: false,
	fill: "#25282e",
};
