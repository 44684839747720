import PropTypes from "prop-types";
import { trans, uctrans } from "../../../../lib/Translator";

export default function LabeledTextarea(props) {
	const onChange = e => {
		props.onChange(e);
	};

	return (
		<>
			<div className="flex justify-between">
				<label htmlFor={typeof props.id === "undefined" ? props.name : props.id}>
					{props.translateLabel ? uctrans(props.label) : props.label}{" "}
					<span className="text-orange">{props.required ? "*" : ""}</span>
				</label>
				{props.showMaxLengthCounter && (
					<span className="italic text-grey text-xs">
						{props.value.length} / {props.maxLength} {trans("general.chars")}
					</span>
				)}
			</div>
			<textarea
				id={typeof props.id === "undefined" ? props.name : props.id}
				name={props.name}
				value={props.value}
				onChange={onChange}
				disabled={props.disabled}
				autoComplete={props.autoComplete ? "on" : "off"}
				maxLength={props.maxLength}
			/>
		</>
	);
}

LabeledTextarea.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	translateLabel: PropTypes.bool,
	value: PropTypes.string.isRequired,
	autoComplete: PropTypes.bool,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	id: PropTypes.string,
	showMaxLengthCounter: PropTypes.bool,
	maxLength: PropTypes.number,
};

LabeledTextarea.defaultProps = {
	autoComplete: false,
	disabled: false,
	translateLabel: true,
	showMaxLengthCounter: false,
};
