import PropTypes from "prop-types";
import { uctrans } from "../../../lib/Translator";
import ReactSelect from "react-select";

export default function Select(props) {
	const styles = {
		control: (base, state) => ({
			...base,
			minHeight: 47,
			lineHeight: 2.3,
			borderRadius: 0,
			boxShadow: "none",
			borderColor: state.isSelected ? "#9c9c9c" : "#cccccc" && state.isFocused ? "#9c9c9c" : "#cccccc",
			"&:hover": {
				borderColor: "#9c9c9c",
			},
		}),
		dropdownIndicator: base => ({
			...base,
			paddingTop: 0,
			paddingBottom: 0,
		}),
		clearIndicator: base => ({
			...base,
			paddingTop: 0,
			paddingBottom: 0,
		}),
		option: (base, state) => ({
			...base,
			color: state.isSelected ? "#ffffff" : "#31353e",
			background: state.isSelected ? "#009286" : "#ffffff" && state.isFocused ? "#D7F0EE" : "#ffffff",
			"&:active": {
				background: "#D7F0EE",
			},
		}),
		menuPortal: base => ({
			...base,
			zIndex: 99999,
		}),
	};

	return (
		<ReactSelect
			placeholder={props.placeholder}
			value={props.value ? props.value : ""}
			options={props.options}
			name={props.name}
			onChange={props.onChange}
			styles={styles}
			isDisabled={props.disabled}
			menuPortalTarget={document.body}
			menuPlacement="auto"
			isClearable={props.isClearable}
			noOptionsMessage={() => uctrans("general.no_options_available")}
		/>
	);
}

Select.propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.any,
	name: PropTypes.string,
	disabled: PropTypes.bool,
	isClearable: PropTypes.bool,
};
Select.defaultProps = {
	placeholder: uctrans("general.select"),
	isClearable: true,
};
