import { useContext } from "react";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import { contactpersonTagText } from "../../../../../js/questionnairehelpers";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import CheckmarkGroup from "../../../../../js/react/components/general/form/checkmark/CheckmarkGroup";
import QuestionnaireContext from "../QuestionnaireContext";

export default function ContactpersonChoiceQuestion(props) {
	const { setAnswer, showColorsAndAdministration } = useContext(QuestionnaireContext);

	const handleSetAnswer = checked => {
		const answer = (checked || []).join(";");
		setAnswer(props.question, answer);
	};

	const getChecked = () => (props.open_content || "").split(";");

	const colorCode = () =>
		showColorsAndAdministration && (
			<span style={{ color: "green" }}>({uctrans("cooperation_check.color_codes.green")})</span>
		);

	const administration = () =>
		showColorsAndAdministration && (
			<span style={{ color: "blue" }}>({uctrans("cooperation_check.annually.needs_administration")})</span>
		);

	const optionText = contactperson => (
		<>
			{contactpersonTagText(contactperson)} {colorCode()} {administration()}
		</>
	);
	return (
		<CheckmarkGroup
			disabled={props.disabled}
			type="radio"
			name={`question-contactperson-choice-${props.question.id}`}
			initialChecked={getChecked()}
			onChange={checked => handleSetAnswer(checked)}>
			{props.contactpersons.map(contactperson => (
				<Checkmark
					key={contactperson.id}
					name={contactperson.id}
					label={optionText(contactperson)}
					value={contactperson.id}
				/>
			))}
		</CheckmarkGroup>
	);
}

ContactpersonChoiceQuestion.propTypes = {
	question: PropTypes.object,
	open_content: PropTypes.string,
	contactpersons: PropTypes.array,
	disabled: PropTypes.bool,
};

ContactpersonChoiceQuestion.defaultProps = {
	disabled: false,
	contactpersons: [],
	open_content: "",
};
