import PropTypes from "prop-types";
import { useState, useCallback, useMemo } from "react";
import { format } from "../../../lib/Date";
import Tippy from "@tippyjs/react";
import { trans, uctrans } from "../../../lib/Translator";
import EvaIcon from "../general/EvaIcon";
import Checkmark from "../general/form/checkmark/Checkmark";
import UnsubscribeReasonDialog from "./UnsubscribeReasonDialog";

export default function CommunicationPreferences(props) {
	const [communicationPreferences, setCommunicationPreferences] = useState(props.communicationPreferenceData || []);
	const [currentType, setCurrentType] = useState(null);
	const [currentCategory, setCurrentCategory] = useState(null);
	const [currentAll, setCurrentAll] = useState(null);
	const numberOfPreferences = useMemo(
		() => props.staticNewsLetterSubscriptionTypes.reduce((sum, type) => sum + type.types.length, 0),
		[props.staticNewsLetterSubscriptionTypes],
	);

	const callbackChange = useCallback(
		newPreferences => {
			props.onPreferenceChange(newPreferences);
			setCommunicationPreferences(newPreferences);
		},
		[props],
	);

	const handlePreferenceChange = (id, remove, reasonId = null) => {
		if (remove) {
			callbackChange(
				communicationPreferences.filter(preference => Number(preference.subscription_type_id) !== Number(id)),
			);
		} else {
			// add it to array
			callbackChange([
				...communicationPreferences,
				{
					subscription_type_id: Number(id),
					when: new Date(),
					unsubscribe_reason_id: reasonId,
				},
			]);
		}
	};
	const handlePreferenceMultiChange = (ids, remove, reasonId = null) => {
		if (remove) {
			callbackChange(
				communicationPreferences.filter(preference => !ids.includes(Number(preference.subscription_type_id))),
			);
		} else {
			// add it to array
			callbackChange(
				[...communicationPreferences].concat(
					ids.map(id => ({
						subscription_type_id: Number(id),
						when: new Date(),
						unsubscribe_reason_id: reasonId,
					})),
				),
			);
		}
	};

	return (
		<div>
			{props.forCrm && props.staticNewsLetterSubscriptionTypes.length > 0 && (
				<div className="mb-8">
					<Checkmark
						name="all_types"
						checked={numberOfPreferences - communicationPreferences.length > 0}
						label={<span className="font-bold">Alle nieuwsbrieven hieronder</span>}
						disabled={props.disabled || numberOfPreferences - communicationPreferences.length <= 0}
						onUnchecked={() => {
							setCurrentAll(true);
						}}
					/>
				</div>
			)}
			{props.staticNewsLetterSubscriptionTypes
				.filter(category => !!category.types && category.types.length > 0)
				.map(category => {
					const categoryPreference = category.types.every(type =>
						communicationPreferences.every(preference => Number(preference.subscription_type_id) !== Number(type.id)),
					);

					const hasMultipleTypes = category.types.length > 1;

					return (
						<div key={`category-${category.id}`} className="mb-5">
							{hasMultipleTypes && (
								<div>
									<Checkmark
										name="category"
										checked={categoryPreference ? 1 : 0}
										label={<span className="font-bold">{category.name}</span>}
										disabled={props.disabled}
										onChecked={() => {
											handlePreferenceMultiChange(
												category.types.map(({ id }) => id),
												true,
											);
										}}
										onUnchecked={() => {
											setCurrentCategory(category);
										}}
									/>
								</div>
							)}
							<div className="w-full">
								{category.types.map(type => {
									const typePreference = communicationPreferences.find(
										preference => Number(preference.subscription_type_id) === Number(type.id),
									);

									const unsubscribeReason =
										typePreference &&
										props.unsubscribeReasons.find(
											({ value }) => Number(value) === Number(typePreference.unsubscribe_reason_id),
										);

									return (
										<div key={`type-${type.id}`} className="flex">
											<div className={`flex-1 flex w-1/2 ${hasMultipleTypes ? "ml-8" : ""}`}>
												<Checkmark
													name="type"
													checked={typePreference ? 0 : 1}
													label={hasMultipleTypes ? type.name : <span className="font-bold">{type.name}</span>}
													disabled={props.disabled}
													onChecked={() => {
														handlePreferenceChange(type.id, true);
													}}
													onUnchecked={() => {
														setCurrentType(type);
													}}
												/>
											</div>
											<div className="w-1/2">
												{type.description && (
													<Tippy content={type.description} placement="top" arrow animation="perspective">
														<span className="mt-2 ml-2">
															<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
														</span>
													</Tippy>
												)}

												{typePreference && (
													<>
														{props.forCrm && (
															<Tippy
																content={uctrans("my_profile.fields.unsubscribed_:at_:by", {
																	at: format(typePreference.when, "dd-MM-y"),
																	by: typePreference.by_object
																		? typePreference.by_object
																		: trans("my_profile.fields.by_receiver_itself"),
																})}
																placement="top"
																arrow
																animation="perspective">
																<span className="mt-2 ml-2">
																	<EvaIcon type="calendar-outline" fill="#249286" height="18" width="18" />
																</span>
															</Tippy>
														)}

														{typePreference.unsubscribe_reason_id && unsubscribeReason && (
															<Tippy
																content={uctrans("my_profile.fields.unsubscribe_reason_:reason", {
																	reason: unsubscribeReason.label ? unsubscribeReason.label : "?",
																})}
																placement="top"
																arrow
																animation="perspective">
																<span className="mt-2 ml-2">
																	<EvaIcon type="message-circle-outline" fill="#249286" height="18" width="18" />
																</span>
															</Tippy>
														)}
													</>
												)}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}

			<UnsubscribeReasonDialog
				isOpen={currentCategory !== null}
				onCancel={() => {
					handlePreferenceMultiChange(
						currentCategory.types.map(({ id }) => id),
						true, // remove
					);
					setCurrentCategory(null);
				}}
				onSelect={selectedReason => {
					handlePreferenceMultiChange(
						currentCategory.types.map(({ id }) => id),
						false, // add
						selectedReason,
					);
					setCurrentCategory(null);
				}}
				unsubscribeReasons={props.unsubscribeReasons}
			/>

			<UnsubscribeReasonDialog
				isOpen={currentType !== null}
				onCancel={() => {
					handlePreferenceChange(currentType.id, true); // remove
					setCurrentType(null);
				}}
				onSelect={selectedReason => {
					handlePreferenceChange(currentType.id, false, selectedReason); // add
					setCurrentType(null);
				}}
				unsubscribeReasons={props.unsubscribeReasons}
			/>

			<UnsubscribeReasonDialog
				isOpen={currentAll !== null}
				onCancel={() => {
					handlePreferenceMultiChange(
						props.staticNewsLetterSubscriptionTypes.flatMap(type => type.types.map(({ id }) => id)),
						true, // remove
					);
					setCurrentAll(null);
				}}
				onSelect={selectedReason => {
					handlePreferenceMultiChange(
						props.staticNewsLetterSubscriptionTypes.flatMap(type => type.types.map(({ id }) => id)),
						false, // add
						selectedReason,
					);
					setCurrentAll(null);
				}}
				unsubscribeReasons={props.unsubscribeReasons}
			/>
		</div>
	);
}

CommunicationPreferences.propTypes = {
	unsubscribeReasons: PropTypes.array,
	staticNewsLetterSubscriptionTypes: PropTypes.array.isRequired,
	communicationPreferenceData: PropTypes.array.isRequired,
	onPreferenceChange: PropTypes.func.isRequired,
	forCrm: PropTypes.bool,
	disabled: PropTypes.bool,
};

CommunicationPreferences.defaultProps = {
	forCrm: false,
	disabled: false,
	unsubscribeReasons: [],
};
