import { useContext } from "react";
import PropTypes from "prop-types";
import QuestionnaireContext from "../QuestionnaireContext";

export default function OpenQuestion(props) {
	const { setAnswer, replaceTags } = useContext(QuestionnaireContext);

	const handleSetAnswer = event => {
		setAnswer(props.question, event.target.value);
	};

	return (
		<div className="container flex flex-col-reverse md:flex-row">
			<span className="form-full">
				<label>
					<span className="question-title" dangerouslySetInnerHTML={{ __html: replaceTags(props.question.content) }} />{" "}
					{props.question.required && <span className="text-orange">*</span>}
				</label>
				{props.multiline && (
					<textarea
						disabled={props.disabled}
						name={`question-open-multi-${props.question.id}`}
						placeholder={props.question.placeholder}
						value={props.open_content}
						onChange={handleSetAnswer}
					/>
				)}
				{!props.multiline && (
					<input
						disabled={props.disabled}
						type="text"
						name={`question-open-${props.question.id}`}
						placeholder={props.question.placeholder}
						value={props.open_content}
						onChange={handleSetAnswer}
					/>
				)}
			</span>
		</div>
	);
}

OpenQuestion.propTypes = {
	disabled: PropTypes.bool,
	question: PropTypes.object,
	open_content: PropTypes.string,
	multiline: PropTypes.bool,
};

OpenQuestion.defaultProps = {
	disabled: false,
	multiline: false,
	open_content: "",
};
