import PropTypes from "prop-types";
import { useContext } from "react";
import QuestionnaireContext from "../QuestionnaireContext";

export default function Information(props) {
	const { replaceTags } = useContext(QuestionnaireContext);

	return (
		<div className="container flex flex-col-reverse md:flex-row">
			<p className="form-full" dangerouslySetInnerHTML={{ __html: replaceTags(props.question.content) }} />
		</div>
	);
}

Information.propTypes = {
	question: PropTypes.object,
};
