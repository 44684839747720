import { useContext } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import find from "lodash/find";
import Select from "../../../../../js/react/components/general/Select";
import QuestionnaireContext from "../QuestionnaireContext";
import { uctrans } from "../../../../../js/lib/Translator";

export default function SelectQuestion(props) {
	const { setAnswer, replaceTags } = useContext(QuestionnaireContext);

	const answerOptionsToSelect = () =>
		map(props.question.answer_options, answer_option => ({ value: answer_option.id, label: answer_option.content }));

	const handleSetAnswer = answerOption => {
		setAnswer(props.question, answerOption.value);
	};

	return (
		<div className="container mb-4 ">
			<div className="flex-row">
				<label>
					<span className="question-title" dangerouslySetInnerHTML={{ __html: replaceTags(props.question.content) }} />{" "}
					{props.question.required && <span className="text-orange">*</span>}
				</label>
			</div>
			<div className="flex-row mb-5">
				<p dangerouslySetInnerHTML={{ __html: replaceTags(props.question.description) }} />
			</div>
			<div className="md:flex-row">
				<Select
					disabled={props.disabled}
					placeholder={uctrans("general.choose_answer")}
					value={find(answerOptionsToSelect(), ["value", props.answer_option_id])}
					options={answerOptionsToSelect()}
					name={`question-mc-select-${props.question.id}`}
					onChange={selectedValue => {
						handleSetAnswer(selectedValue);
					}}
					isClearable={false}
				/>
			</div>
		</div>
	);
}

SelectQuestion.propTypes = {
	question: PropTypes.object,
	answer_option_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	disabled: PropTypes.bool,
};

SelectQuestion.defaultProps = {
	disabled: false,
	answer_option_id: "",
};
