import PropTypes from "prop-types";
import EvaIcon from "../general/EvaIcon";

import sustainabilityEnergylabelA from "../../../../static/img/client_leads/sustainability_energylabel_a.svg";
import sustainabilityEnergylabelB from "../../../../static/img/client_leads/sustainability_energylabel_b.svg";
import sustainabilityEnergylabelC from "../../../../static/img/client_leads/sustainability_energylabel_c.svg";
import sustainabilityEnergylabelD from "../../../../static/img/client_leads/sustainability_energylabel_d.svg";
import sustainabilityEnergylabelE from "../../../../static/img/client_leads/sustainability_energylabel_e.svg";
import sustainabilityEnergylabelF from "../../../../static/img/client_leads/sustainability_energylabel_f.svg";
import sustainabilityEnergylabelG from "../../../../static/img/client_leads/sustainability_energylabel_g.svg";
import sustainabilityEnergylabelX from "../../../../static/img/client_leads/sustainability_energylabel_x.svg";
import sustainabilityTemporaryEnergylabelA from "../../../../static/img/client_leads/sustainability_temporary_energylabel_a.svg";
import sustainabilityTemporaryEnergylabelB from "../../../../static/img/client_leads/sustainability_temporary_energylabel_b.svg";
import sustainabilityTemporaryEnergylabelC from "../../../../static/img/client_leads/sustainability_temporary_energylabel_c.svg";
import sustainabilityTemporaryEnergylabelD from "../../../../static/img/client_leads/sustainability_temporary_energylabel_d.svg";
import sustainabilityTemporaryEnergylabelE from "../../../../static/img/client_leads/sustainability_temporary_energylabel_e.svg";
import sustainabilityTemporaryEnergylabelF from "../../../../static/img/client_leads/sustainability_temporary_energylabel_f.svg";
import sustainabilityTemporaryEnergylabelG from "../../../../static/img/client_leads/sustainability_temporary_energylabel_g.svg";
import sustainabilityTemporaryEnergylabelX from "../../../../static/img/client_leads/sustainability_temporary_energylabel_x.svg";
import seniorsOvervalue from "../../../../static/img/client_leads/seniors_overvalue-2.svg";
import movingRenovating from "../../../../static/img/client_leads/moving_renovating.svg";
import interestRenewal from "../../../../static/img/client_leads/interest_renewal.svg";

export default function LeadIcon({ type, status, energylabel, energylabelFinal, width, className, iconUrl }) {
	const getSustainabilitySvg = () => {
		switch (energylabel) {
			case "A":
				return energylabelFinal ? sustainabilityEnergylabelA : sustainabilityTemporaryEnergylabelA;
			case "B":
				return energylabelFinal ? sustainabilityEnergylabelB : sustainabilityTemporaryEnergylabelB;
			case "C":
				return energylabelFinal ? sustainabilityEnergylabelC : sustainabilityTemporaryEnergylabelC;
			case "D":
				return energylabelFinal ? sustainabilityEnergylabelD : sustainabilityTemporaryEnergylabelD;
			case "E":
				return energylabelFinal ? sustainabilityEnergylabelE : sustainabilityTemporaryEnergylabelE;
			case "F":
				return energylabelFinal ? sustainabilityEnergylabelF : sustainabilityTemporaryEnergylabelF;
			case "G":
				return energylabelFinal ? sustainabilityEnergylabelG : sustainabilityTemporaryEnergylabelG;
			default:
				return energylabelFinal ? sustainabilityEnergylabelX : sustainabilityTemporaryEnergylabelX;
		}
	};

	const getSvg = () => {
		switch (type) {
			case "sustainability":
				return getSustainabilitySvg();
			case "seniors_overvalue":
				return seniorsOvervalue;
			case "moving_renovating":
				return movingRenovating;
			case "interest_renewal":
				return interestRenewal;
			default:
				return null;
		}
	};

	const getStyle = () => {
		const height = 0 + width;
		const style = { backgroundImage: `url(${iconUrl ?? getSvg()})`, width, height };
		if (status !== "open") {
			style.opacity = 0.4;
		}
		return style;
	};

	return (
		<div className="relative inline-block">
			<div className={`bg-cover bg-no-repeat inset-0 ${className ?? ""}`} style={getStyle()}></div>
			{status === "handled_positive" && (
				<span className="absolute top-0 rounded-full w-1/2 h-1/2 bg-white">
					<EvaIcon type="checkmark-circle-2" fill="#019294" width="100%" height="100%" />
				</span>
			)}
			{status === "handled_negative" && (
				<span className="absolute top-0 rounded-full w-1/2 h-1/2 bg-white">
					<EvaIcon type="minus-circle" fill="#ff6623" width="100%" height="100%" />
				</span>
			)}
		</div>
	);
}

LeadIcon.propTypes = {
	type: PropTypes.string.isRequired,
	status: PropTypes.string,
	energylabel: PropTypes.string,
	energylabelFinal: PropTypes.bool,
	width: PropTypes.number,
	className: PropTypes.string,
	iconUrl: PropTypes.string,
};

LeadIcon.defaultProps = {
	width: 35,
	status: "open",
	energylabelFinal: true,
};
