import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { can, route } from "../../../helpers";
import { format } from "../../../lib/Date";
import { uctrans } from "../../../lib/Translator";
import Dialog from "../general/Dialog";
import EvaIcon from "../general/EvaIcon";
import AutosizeTextarea from "../general/form/AutosizeTextarea";
import { OverviewContext } from "../general/overview/overview-context";
import MailSent from "../../../../static/img/mail_sent.svg";
import EnergySaverForm from "./EnergySaverForm";
import LeadIcon from "./LeadIcon";
import axios from "axios";
import Tippy from "@tippyjs/react";
import EnergyPlugSVG from "../../../../static/img/client_leads/energy_plug.svg";
import { getTagReplaceText } from "./utils";

export default function LeadManageItem({ lead, forWebsite, onChange, useOverviewContext }) {
	const leadSettings = window.data.lead_settings;

	const [status, setStatus] = useState(lead.status);
	const [comments, setComments] = useState(lead.comments);
	const [openMailDialog, setOpenMailDialog] = useState(false);
	const [editingComments, setEditingComments] = useState(false);
	const [copied, setCopied] = useState(false);
	const [consentEbc, setConsentEbc] = useState(false);
	const [consentEbcDialogOpen, setConsentEbcDialogOpen] = useState(false);

	const { loadData } = useContext(useOverviewContext ? OverviewContext : createContext({ loadData: () => {} }));

	const closeConsentDialog = useCallback(() => {
		setConsentEbcDialogOpen(false);
		setConsentEbc(false);
	}, []);

	useEffect(() => {
		setStatus(lead.status);
		setComments(lead.comments);
	}, [lead]);

	const settings = useMemo(() => {
		let detailText = "";
		if (lead) {
			switch (lead.type) {
				case "sustainability":
					if (
						leadSettings.sustainability_energylabels &&
						leadSettings.sustainability_energylabels.includes(lead.energylabel.toLowerCase())
					) {
						detailText = leadSettings.sustainability_detail_text;
					} else if (
						leadSettings.sustainability_2_energylabels &&
						leadSettings.sustainability_2_energylabels.includes(lead.energylabel.toLowerCase())
					) {
						detailText = leadSettings.sustainability_2_detail_text;
					}

					return {
						in_use: !!leadSettings.use_sustainability,
						detail_text: detailText.replace("{energylabel}", getTagReplaceText(lead)),
					};
				case "seniors_overvalue":
					return {
						in_use: !!leadSettings.use_seniors_overvalue,
						detail_text: leadSettings.seniors_overvalue_detail_text,
						icon_idtoken: leadSettings.seniors_overvalue_icon.id_token,
					};
				case "moving_renovating":
					return {
						in_use: !!leadSettings.use_moving_renovating,
						detail_text: leadSettings.moving_renovating_detail_text,
						icon_idtoken: leadSettings.moving_renovating_icon.id_token,
					};
				case "interest_renewal":
					return {
						in_use: !!leadSettings.use_interest_renewal,
						detail_text: leadSettings.interest_renewal_detail_text,
						icon_idtoken: leadSettings.interest_renewal_icon.id_token,
					};
				default:
					return false;
			}
		}
		return null;
	}, [leadSettings, lead]);

	const updateLead = async newStatus => {
		if (forWebsite) {
			let newComments = comments;
			if (newStatus === "open") {
				newComments = "";
				setComments(newComments);
			}

			try {
				setStatus(newStatus);
				await axios.put(
					route("website.client-consents.client-leads.update", {
						client_consent: lead.consent_id,
						client_lead: lead.id,
					}),
					{
						status: newStatus,
						comments: newComments,
					},
				);
				loadData();
				if (onChange) {
					onChange(lead);
				}
			} catch (err) {
				console.error(err);
			}
		}
	};

	const handleCopy = useCallback(() => {
		const tempInput = document.createElement("textarea");
		tempInput.style.position = "absolute";
		tempInput.style.left = "-1000px";
		tempInput.style.top = "-1000px";

		tempInput.value = lead.email_data.stripped_body || "";
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);
		setCopied(true);

		setTimeout(() => setCopied(false), 2000); // Reset after two seconds
	}, [lead.email_data.stripped_body]);

	return (
		settings.in_use && (
			<div className="w-full flex p-4 items-start">
				<div className="w-1/4">
					<LeadIcon
						status={lead.status}
						type={lead.type}
						energylabel={lead.energylabel}
						energylabelFinal={lead.energylabel_final}
						width={100}
						iconUrl={
							settings.icon_idtoken
								? route(`${forWebsite ? "website" : "crm"}.files.public-download`, { idtoken: settings.icon_idtoken })
								: null
						}
					/>
				</div>
				<div className="w-3/4">
					<strong>{format(lead.created_at, "dd-MM-y")}</strong>

					{forWebsite && lead.email_data ? (
						<div className="flex items-stretch">
							<div
								className="w-11/12 content-block html-content"
								dangerouslySetInnerHTML={{ __html: settings.detail_text }}
							/>

							<div className="w-1/12 inline-block self-center text-right">
								{lead.type === "sustainability" && (
									<div className="mb-2">
										<a onClick={() => setConsentEbcDialogOpen(true)}>
											<Tippy
												placement="top"
												animation="perspective"
												duration="400"
												content={uctrans("client_consents.do_energy_savers_check")}>
												<img src={EnergyPlugSVG} height={64} width={64} alt="energie_bespaar_check" />
											</Tippy>
										</a>
									</div>
								)}

								<a onClick={() => setOpenMailDialog(!openMailDialog)}>
									<Tippy
										placement="top"
										animation="perspective"
										duration="400"
										content={uctrans("client_consents.send_mail_to_customer_about_lead")}>
										<img src={MailSent} height={64} alt="verstuur_mail" />
									</Tippy>
								</a>
							</div>
						</div>
					) : (
						<div className="content-block html-content" dangerouslySetInnerHTML={{ __html: settings.detail_text }} />
					)}

					{forWebsite && can("client_consent.client_lead", "update") && status === "open" && (
						<div className=" mt-5 w-full mb-3 flex">
							<a
								className="button button-secondary text-xs button-adjustment mr-3"
								onClick={async () => {
									setEditingComments(true);
									await updateLead("handled_positive");
								}}>
								<span className="block" style={{ width: 200 }}>
									{uctrans("client_consents.leads.handled_with_success")}
								</span>
							</a>
							<a
								className="button button-primary text-xs button-adjustment mr-3"
								onClick={async () => {
									setEditingComments(true);
									await updateLead("handled_negative");
								}}>
								<span className="block" style={{ width: 200 }}>
									{uctrans("client_consents.leads.handled_without_success")}
								</span>
							</a>
						</div>
					)}

					{status === "handled_positive" && (
						<strong className="text-green">{uctrans("client_consents.leads.handled_with_success")}</strong>
					)}
					{status === "handled_negative" && (
						<strong style={{ color: "#ff6623" }}>{uctrans("client_consents.leads.handled_without_success")}</strong>
					)}

					{forWebsite && can("client_consent.client_lead", "update") && status !== "open" && editingComments && (
						<div>
							<AutosizeTextarea
								id="comments"
								name="comments"
								value={comments ?? ""}
								onChange={e => setComments(e.target.value)}
								placeholder={uctrans("client_consents.leads.comments_placeholder")}
							/>
							<a
								className="button button-tertiary text-xs"
								onClick={async () => {
									setEditingComments(false);
									await updateLead(status);
								}}>
								{uctrans("client_consents.leads.save_comments")}
							</a>
						</div>
					)}
					{status !== "open" && !editingComments && (
						<div className="w-full flex justify-between">
							<p className="italic whitespace-pre">
								{comments ? comments : uctrans("client_consents.leads.no_comments")}
							</p>
							{can("client_consent.client_lead", "update") && (
								<div className="flex">
									<Tippy
										placement="left"
										arrow
										animation="perspective"
										duration="400"
										content={uctrans("client_consents.leads.edit_comments")}>
										<span className="mr-4" onClick={() => setEditingComments(true)}>
											<EvaIcon fill="#249286" width="18" height="18" type="edit" />
										</span>
									</Tippy>
									<Tippy
										placement="left"
										arrow
										animation="perspective"
										duration="400"
										content={uctrans("client_consents.leads.reset_status")}>
										<span
											onClick={async () => {
												setEditingComments(false);
												await updateLead("open");
											}}>
											<EvaIcon fill="#249286" width="18" height="18" type="undo" />
										</span>
									</Tippy>
								</div>
							)}
						</div>
					)}
				</div>
				<Dialog
					width={500}
					isOpen={openMailDialog !== false}
					onClose={() => setOpenMailDialog(false)}
					title={uctrans("client_consents.leads.send_mail")}>
					<div className="bg-primary-lightest p-4 text-xs" dangerouslySetInnerHTML={{ __html: lead.email_data.body }} />
					<br />
					<a
						className="button button-secondary text-xs mr-5"
						style={{ width: 150 }}
						href={`mailto:?subject=${encodeURIComponent(lead.email_data.subject || "")}&body=${encodeURIComponent(
							lead.email_data.stripped_body || "",
						)}`}>
						{uctrans("client_consents.leads.send_with_email")}
					</a>
					<a className="button button-secondary text-xs" onClick={() => handleCopy()} style={{ width: 150 }}>
						{copied ? uctrans("client_consents.leads.text_copied") : uctrans("client_consents.leads.copy_text")}
					</a>
					<div className="text-sm mt-3">{uctrans("client_consents.leads.send_mail_explanation")}</div>
				</Dialog>

				{window.data.energy_saving_refer_url && (
					<EnergySaverForm
						consentId={lead.consent_id}
						isOpen={consentEbcDialogOpen}
						consentEbc={consentEbc}
						closeDialog={closeConsentDialog}
						onChange={checked => setConsentEbc(checked)}
						energySavingReferUrl={window.data.energy_saving_refer_url}
					/>
				)}
			</div>
		)
	);
}

LeadManageItem.propTypes = {
	lead: PropTypes.shape({
		id: PropTypes.number,
		consent_id: PropTypes.number,
		type: PropTypes.string,
		energylabel: PropTypes.string,
		energylabel_final: PropTypes.string,
		status: PropTypes.string,
		comments: PropTypes.string,
		created_at: PropTypes.string,
		email_data: PropTypes.shape({
			body: PropTypes.string,
			stripped_body: PropTypes.string,
			subject: PropTypes.string,
		}),
	}),
	forWebsite: PropTypes.bool,
	onChange: PropTypes.func,
	useOverviewContext: PropTypes.bool,
};

LeadManageItem.defaultProps = {
	forWebsite: true,
	useOverviewContext: true,
};
