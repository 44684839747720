import { useContext } from "react";
import QuestionnaireContext from "./QuestionnaireContext";
import Question from "./Question";

export default function QuestionList() {
	const { currentPage, allQuestions } = useContext(QuestionnaireContext);

	const questions = currentPage ? currentPage.questions : allQuestions;

	return (
		<div className="container">
			{questions.map(({ ...question }) => (
				<Question key={question.id} question={question} />
			))}
		</div>
	);
}
