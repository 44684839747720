import PropTypes from "prop-types";
import { uctrans } from "../../../lib/Translator";
import EvaIcon from "./EvaIcon";
import DebouncedInput from "./form/DebouncedInput";

export default function SearchBar({ onChange, name, placeholder, value, hideIcon, withSubmit, showClearButton }) {
	return (
		<div className="search-bar">
			<DebouncedInput
				type="text"
				className="search-bar-input"
				name={name}
				placeholder={placeholder}
				value={value || ""}
				onChange={onChange}
			/>

			{withSubmit ? (
				<button type="submit">
					{hideIcon ? null : (
						<EvaIcon type="search" className="search-placeholder" fill="#687B91" height="22" width="22" />
					)}
				</button>
			) : hideIcon ? null : (
				<EvaIcon type="search" className="search-placeholder" fill="#687B91" height="22" width="22" />
			)}
			{showClearButton && value !== "" && value != null && (
				<span
					className="search-clear-button"
					onClick={() => {
						onChange({
							target: {
								value: "",
								type: "text",
								name,
							},
						});
					}}>
					<EvaIcon type="close-outline" fill="#687B91" height="22" width="22" />
				</span>
			)}
		</div>
	);
}

SearchBar.propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	withSubmit: PropTypes.bool,
	hideIcon: PropTypes.bool,
	showClearButton: PropTypes.bool,
};

SearchBar.defaultProps = {
	placeholder: uctrans("general.search"),
	showClearButton: true,
};
