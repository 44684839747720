import PropTypes from "prop-types";
import { uctrans } from "../../../../lib/Translator";
import { OverviewContext } from "./overview-context";
import Select from "../Select";

export default function PageLimitSelect(props) {
	const getPageLimitState = overview => {
		let options = [];
		if (props.options) {
			({ options } = props);
		} else {
			options = [10, 25, 50];
		}
		return {
			overview,
			component: this,
			currentLimit: overview.data.metadata.pagination.limit,
			options,
			setLimit: limit => {
				setLimit(overview, limit);
			},
		};
	};

	const setLimit = (overview, limit) => {
		const { data } = overview;
		data.metadata.pagination.page = 1;
		data.metadata.pagination.limit = limit;
		overview.setData(data);
	};

	const renderAs = pageLimit => {
		if (props.renderAs) {
			return props.renderAs(pageLimit);
		} else if (pageLimit.overview.component.getConfig("PageLimitSelect", "renderAs")) {
			return pageLimit.overview.component.getConfig("PageLimitSelect", "renderAs")(pageLimit);
		}
		const options = pageLimit.options.map(number => ({
			value: number,
			label: uctrans("overview.:number_per_page", { number }),
		}));
		const selectedOption = options.find(option => option.value === pageLimit.currentLimit);
		return (
			<div className="overview-limit">
				<Select
					onChange={value => {
						pageLimit.setLimit(value.value);
					}}
					value={selectedOption}
					options={options}
					isClearable={false}
				/>
			</div>
		);
	};

	return <OverviewContext.Consumer>{overview => renderAs(getPageLimitState(overview))}</OverviewContext.Consumer>;
}

PageLimitSelect.propTypes = {
	// Bootstrap
	renderAs: PropTypes.func,
	options: PropTypes.array,
};
