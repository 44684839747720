import PropTypes from "prop-types";
import { Children, cloneElement, Component } from "react";

class CheckmarkGroup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedValues: props.initialChecked || [],
		};

		this.handleSelectionChange = this.handleSelectionChange.bind(this);
	}

	selectItem(value) {
		return [...this.state.selectedValues, value];
	}

	unSelectItem(value) {
		const newSelectedValues = [...this.state.selectedValues];
		const index = newSelectedValues.indexOf(value);
		if (index !== -1) {
			newSelectedValues.splice(index, 1);
			return newSelectedValues;
		}
		return newSelectedValues;
	}

	handleSelectionChange(e) {
		const { target } = e;

		const selectedValues = target.checked ? this.selectItem(target.name) : this.unSelectItem(target.name);

		this.setState(
			{
				selectedValues,
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange(selectedValues);
				}
			},
		);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.initialChecked !== state.selectedValues) {
			return {
				...state,
				selectedValues: props.initialChecked || [],
			};
		} else {
			return state;
		}
	}

	render() {
		return Children.map(this.props.children, (child, index) => {
			if (!child) {
				return child; // if it's null or false, just use the normal procedure
			}

			const value = typeof child.props.value !== "undefined" ? child.props.value : index;
			return cloneElement(child, {
				key: value,
				rel: this.props.name,
				onChange: this.handleSelectionChange,
				checked: this.state.selectedValues.some(checkedValue => `${checkedValue}` === `${value}`),
				disabled: this.props.disabled,
			});
		});
	}
}

export default CheckmarkGroup;

CheckmarkGroup.propTypes = {
	name: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	initialChecked: PropTypes.array,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
};
