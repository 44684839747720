import PropTypes from "prop-types";
import { trans } from "../../../lib/Translator";

export default function Translate(props) {
	return trans(props.content, props.replaces || {}, props.transReplaces || {}, props.ucfirst);
}

Translate.propTypes = {
	content: PropTypes.string.isRequired,
	replaces: PropTypes.object,
	ucfirst: PropTypes.bool,
	transReplaces: PropTypes.object,
};

Translate.defaultProps = {
	ucfirst: true,
};
