import dateFnsAddDays from "date-fns/addDays";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import dateFnsFormatDistanceStrict from "date-fns/formatDistanceStrict";
import dateFnsFormatDistance from "date-fns/formatDistance";
import dateFnsFormatRelative from "date-fns/formatRelative";
import { registerLocale } from "react-datepicker";

/**
 * Load formats
 */
import en from "date-fns/locale/en-GB";
import nl from "date-fns/locale/nl";

const locales = {
	nl,
};

let locale;
export const setLocale = newLocale => {
	locale = locales.hasOwnProperty(newLocale) ? locales[newLocale] : en;
	registerLocale(newLocale, locale);
};

setLocale("nl");

export const default_date_time_format = "yyyy-MM-dd HH:mm:ss";
export const default_date_format = "yyyy-MM-dd";

export const addDays = (date, amount) => dateFnsAddDays(date, amount);

export const format = (date, format = default_date_time_format, options = {}) =>
	dateFnsFormat(typeof date === "string" ? parseISO(date) : date, format, {
		locale,
		...options,
	});
export const formatDistance = (date, baseDate = new Date(), options = {}) =>
	dateFnsFormatDistance(
		typeof date === "string" ? parseISO(date) : date,
		typeof baseDate === "string" ? parseISO(baseDate) : baseDate,
		{
			locale,
			...options,
		},
	);

export const parse = (date, date_format = default_date_time_format, baseDate = new Date(), options = {}) => {
	date = typeof date === "string" ? date : format(date, date_format);

	return dateFnsParse(date, date_format, baseDate, {
		locale,
		...options,
	});
};

export const fromNow = (date, options = {}) =>
	dateFnsFormatDistanceStrict(typeof date === "string" ? parseISO(date) : date, new Date(), {
		addSuffix: true,
		locale,
		...options,
	});

export const fromNowRelative = (date, options = {}) =>
	dateFnsFormatRelative(typeof date === "string" ? parseISO(date) : date, new Date(), {
		addSuffix: true,
		locale,
		...options,
	});

export const shortMonths = () =>
	[...Array(12).keys()].map(index =>
		getLocale().localize.month(index, {
			width: "abbreviated",
		}),
	);

export const weekDays = () =>
	[...Array(7).keys()].map(index =>
		getLocale().localize.day(index, {
			width: "wide",
		}),
	);

export const getLocale = () => locale;
