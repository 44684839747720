export default function useInputHandler(setState) {
	if (typeof setState !== "function") {
		console.error("useInputHandler expects a setState function as argument");
	}

	return event => {
		const { target } = event;
		const value = target.type === "checkbox" ? target.checked : target.value;

		setState(state => ({
			...state,
			[target.name]: value,
		}));
	};
}
