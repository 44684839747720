import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import KifidTextInput from "../../../../../js/react/components/general/form/KifidTextInput";
import KvkTextInput from "../../../../../js/react/components/general/form/KvkTextInput";
import UpperCaseTextInput from "../../../../../js/react/components/general/form/UpperCaseTextInput";
import ZipCodeTextInput from "../../../../../js/react/components/general/form/ZipCodeTextInput";
import Select from "../../../../../js/react/components/general/Select";
import QuestionnaireContext from "../QuestionnaireContext";
import ContactpersonChoiceQuestion from "./ContactpersonChoiceQuestion";
import SoftwarePackageQuestion from "./SoftwarePackageQuestion";

export default function LinkedFieldQuestion(props) {
	const { questionnaire, setAnswer, replaceTags, colors, showColorsAndAdministration, questionnaireTags } =
		useContext(QuestionnaireContext);

	const { organisations, identification_verification, software_packages, contactpersons } = questionnaireTags;

	const identificationVerificationOptions = identification_verification.map(identificationVerification => ({
		value: `${identificationVerification.id}`,
		label: `${identificationVerification.questionnaire_explanation}`,
	}));

	const currentOptions = key => {
		switch (Number(key)) {
			case Number(questionnaire.linked_field_types.ORGANISATION.key):
				return organisations;
			case Number(questionnaire.linked_field_types.IDENTIFICATION_VERIFICATION.key):
				return identificationVerificationOptions;
		}
	};

	const handleSetSelectAnswer = selectedValue => {
		setAnswer(props.question, selectedValue);
	};

	const handleSetAnswer = event => {
		if (event.target.name === "extra_answer") {
			setAnswer(props.question, `${props.open_content};${event.target.value}`);
		} else {
			setAnswer(props.question, event.target.value);
		}
	};

	const getIdentificationVerificationColorCode = id => {
		const currentIdentificationVerification = identification_verification.find(
			identificationVerification => `${identificationVerification.id}` === `${id}`,
		);

		if (currentIdentificationVerification) {
			return currentIdentificationVerification.color_code;
		}
		return null;
	};

	const colorCode = colorCode => {
		switch (Number(colorCode)) {
			case colors.GREEN.key:
				return <span style={{ color: "green" }}>({uctrans("cooperation_check.color_codes.green")})</span>;
			case colors.RED.key:
				return <span style={{ color: "red" }}>({uctrans("cooperation_check.color_codes.red")})</span>;
			case colors.ORANGE.key:
				return <span style={{ color: "orange" }}>({uctrans("cooperation_check.color_codes.orange")})</span>;
			default:
				return "";
		}
	};

	const defaultContainer = children => (
		<div className="container flex flex-col-reverse md:flex-row">
			<span className="form-full">
				<label>
					<span className="question-title" dangerouslySetInnerHTML={{ __html: replaceTags(props.question.content) }} />{" "}
					{props.question.required && <span className="text-orange">*</span>}
				</label>
				{children}
			</span>
		</div>
	);

	switch (Number(props.question.linked_field_type)) {
		case questionnaire.linked_field_types.CITY.key:
			return defaultContainer(
				<UpperCaseTextInput
					disabled={props.disabled}
					name={`question-open-${props.question.id}`}
					value={props.open_content}
					onChange={handleSetAnswer}
					placeholder={props.question.placeholder}
				/>,
			);
		case questionnaire.linked_field_types.ZIPCODE.key:
			return defaultContainer(
				<ZipCodeTextInput
					disabled={props.disabled}
					name={`question-open-${props.question.id}`}
					value={props.open_content}
					onChange={handleSetAnswer}
					placeholder={props.question.placeholder}
				/>,
			);
		case questionnaire.linked_field_types.KIFID_NUMBER.key:
			return defaultContainer(
				<KifidTextInput
					disabled={props.disabled}
					name={`question-open-${props.question.id}`}
					value={props.open_content}
					onChange={handleSetAnswer}
					placeholder={props.question.placeholder}
				/>,
			);
		case questionnaire.linked_field_types.KVK_NUMBER.key:
		case questionnaire.linked_field_types.AFM_NUMBER.key:
			return defaultContainer(
				<KvkTextInput
					disabled={props.disabled}
					name={`question-open-${props.question.id}`}
					value={props.open_content}
					onChange={handleSetAnswer}
					placeholder={props.question.placeholder}
				/>,
			);

		case questionnaire.linked_field_types.ORGANISATION.key:
		case questionnaire.linked_field_types.IDENTIFICATION_VERIFICATION.key:
			return (
				<div className="container mb-4">
					<div className="flex md:flex-row">
						<label>
							<span
								className="question-title"
								dangerouslySetInnerHTML={{ __html: replaceTags(props.question.content) }}
							/>{" "}
							{props.question.required && <span className="text-orange">*</span>}
						</label>
					</div>
					{showColorsAndAdministration &&
						`${props.question.linked_field_type}` ===
							`${questionnaire.linked_field_types.IDENTIFICATION_VERIFICATION.key}` &&
						colorCode(getIdentificationVerificationColorCode(props.open_content))}
					<div className="md:flex-row">
						<Select
							disabled={props.disabled}
							placeholder={uctrans("general.choose_answer")}
							value={find(currentOptions(props.question.linked_field_type), ["value", props.open_content])}
							options={currentOptions(props.question.linked_field_type)}
							name={`question-mc-select-${props.question.id}`}
							onChange={selectedValue => {
								handleSetSelectAnswer(selectedValue ? selectedValue.value : null);
							}}
							isClearable
						/>
					</div>
				</div>
			);
		case questionnaire.linked_field_types.SOFTWARE_PACKAGE.key:
			return (
				<SoftwarePackageQuestion
					question={props.question}
					multiline={props.multiline}
					open_content={props.open_content}
					softwarePackages={software_packages}
				/>
			);
		case questionnaire.linked_field_types.CONTACTPERSONS.key:
			return defaultContainer(
				<ContactpersonChoiceQuestion
					open_content={props.open_content}
					question={props.question}
					contactpersons={contactpersons}
				/>,
			);
		default:
			return defaultContainer(
				<>
					{props.multiline && (
						<textarea
							disabled={props.disabled}
							name={`question-open-multi-${props.question.id}`}
							placeholder={props.question.placeholder}
							value={props.open_content}
							onChange={handleSetAnswer}
						/>
					)}
					{!props.multiline && (
						<input
							disabled={props.disabled}
							type="text"
							name={`question-open-${props.question.id}`}
							placeholder={props.question.placeholder}
							value={props.open_content}
							onChange={handleSetAnswer}
						/>
					)}
				</>,
			);
	}
}

LinkedFieldQuestion.propTypes = {
	question: PropTypes.object,
	open_content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	multiline: PropTypes.bool,
	disabled: PropTypes.bool,
};

LinkedFieldQuestion.defaultProps = {
	disabled: false,
	multiline: false,
	open_content: "",
};
