import PropTypes from "prop-types";
import { trans, uctrans } from "../../../../lib/Translator";
import EvaIcon from "../EvaIcon";
import Tippy from "@tippyjs/react";

export default function LabeledTextInput(props) {
	return (
		<>
			<div className="flex justify-between">
				<label htmlFor={typeof props.id === "undefined" ? props.name : props.id}>
					{props.translateLabel ? uctrans(props.label) : props.label}{" "}
					<span className="text-orange">{props.required ? "*" : ""}</span>
					{props.hint && (
						<Tippy placement="auto" arrow animation="perspective" content={props.hint}>
							<span className="ml-2">
								<EvaIcon type="info" fill="#249286" height="19" width="19" />
							</span>
						</Tippy>
					)}
				</label>
				{props.showMaxLengthCounter && props.value && (
					<span className="italic text-grey text-xs">
						{props.value.length} / {props.maxLength} {trans("general.chars")}
					</span>
				)}
			</div>
			<input
				id={typeof props.id === "undefined" ? props.name : props.id}
				type={props.type}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
				autoComplete={props.autoComplete ? "on" : "off"}
				placeholder={props.placeholder}
			/>
		</>
	);
}

LabeledTextInput.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	translateLabel: PropTypes.bool,
	value: PropTypes.string.isRequired,
	autoComplete: PropTypes.bool,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	type: PropTypes.string,
	id: PropTypes.string,
	placeholder: PropTypes.string,
	hint: PropTypes.string,
	showMaxLengthCounter: PropTypes.bool,
	maxLength: PropTypes.number,
};

LabeledTextInput.defaultProps = {
	autoComplete: false,
	type: "text",
	disabled: false,
	translateLabel: true,
	showMaxLengthCounter: false,
};
