import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import LabeledTextarea from "../../../../../../js/react/components/general/form/LabeledTextarea";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import Translate from "../../../../../../js/react/components/general/Translate";

export default function DiscussRequestScore({ initialScore, onUpdate, viewedByRelationmanager }) {
	const [score, setScore] = useState(initialScore);

	const handleChange = useCallback(
		(field, value) => {
			const newScore = { ...score };
			newScore[field] = value;
			setScore(newScore);
			onUpdate(newScore);
		},
		[score, onUpdate],
	);

	return (
		<div className="form-container">
			<div className="form-full">
				<h3>{uctrans("discuss_requests.score.rm_score")}</h3>
				{score.last_edited_by_relationmanager ? (
					<span className="italic">
						{uctrans("discuss_requests.score.last_edited_by:rm", {
							rm: score.last_edited_by_relationmanager.full_name,
						})}
					</span>
				) : null}
			</div>

			<div className="form-full">
				<label htmlFor="commercial">
					<Translate content="discuss_requests.score.fields.commercial" />
				</label>
				<RadioGroup
					disabled={!viewedByRelationmanager}
					name="commercial"
					value={score.commercial}
					onChange={value => handleChange("commercial", value)}>
					<Radio label={uctrans("general.yes")} value />
					<Radio label={uctrans("general.no")} value={false} />
				</RadioGroup>
			</div>

			<div className="form-full">
				<label htmlFor="relevant">
					<Translate content="discuss_requests.score.fields.relevant" />
				</label>
				<RadioGroup
					disabled={!viewedByRelationmanager}
					name="relevant"
					value={score.relevant}
					onChange={value => handleChange("relevant", value)}>
					<Radio label={uctrans("general.yes")} value />
					<Radio label={uctrans("general.no")} value={false} />
				</RadioGroup>
			</div>

			<div className="form-full">
				<LabeledTextarea
					disabled={!viewedByRelationmanager}
					label="discuss_requests.score.fields.comments"
					name="comments"
					value={score.comments || ""}
					onChange={e => handleChange(e.target.name, e.target.value)}
				/>
			</div>
		</div>
	);
}

DiscussRequestScore.propTypes = {
	initialScore: PropTypes.object,
	onUpdate: PropTypes.func,
	viewedByRelationmanager: PropTypes.bool,
};
