import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { route } from "../../../helpers";
import { uctrans } from "../../../lib/Translator";
import Dialog from "../general/Dialog";
import Checkmark from "../general/form/checkmark/Checkmark";
import axios from "axios";

export default function EnergySaverForm({
	consentId,
	closeDialog,
	isOpen,
	onChange,
	consentEbc,
	energySavingReferUrl,
}) {
	const [zipCode, setZipCode] = useState(null);
	const [houseNumber, setHouseNumber] = useState(null);
	const [houseNumberExtension, setHouseNumberExtension] = useState(null);

	const getConsentData = useCallback(async () => {
		if (!consentId || !isOpen) {
			return;
		}
		try {
			const { data } = await axios.get(route("website.client-consents.show", consentId));
			if (data && data.client_consent) {
				if (data.client_consent.collateral_address_number) {
					setHouseNumber(data.client_consent.collateral_address_number);
				}
				if (data.client_consent.collateral_address_zipcode) {
					setZipCode(data.client_consent.collateral_address_zipcode);
				}
				if (data.client_consent.collateral_address_addition) {
					setHouseNumberExtension(data.client_consent.collateral_address_addition);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}, [consentId, isOpen]);

	useEffect(() => {
		getConsentData();
	}, [consentId, isOpen, getConsentData]);

	return (
		<Dialog title={uctrans("client_consents.consent")} isOpen={isOpen} onClose={closeDialog}>
			<div className="form-full">
				<Checkmark
					label={uctrans("client_consents.consent_ebc_explanation")}
					checked={consentEbc}
					onChange={e => onChange(e.target.checked)}
				/>
				<form
					method="POST"
					target="_blank"
					action={energySavingReferUrl}
					onSubmit={() => setTimeout(() => closeDialog(), 100)}>
					{zipCode && <input readOnly hidden value={zipCode} name="postCode" />}
					{houseNumber && <input readOnly hidden value={houseNumber} name="houseNumber" />}
					{houseNumberExtension && <input readOnly hidden value={houseNumberExtension} name="houseNumberExtension" />}
					<button disabled={!consentEbc} className="button button-primary">
						{uctrans("client_consents.do_energy_savers_check")}
					</button>
				</form>
			</div>
		</Dialog>
	);
}

EnergySaverForm.propTypes = {
	consentId: PropTypes.number,
	closeDialog: PropTypes.func,
	isOpen: PropTypes.bool,
	onChange: PropTypes.func,
	consentEbc: PropTypes.bool,
	energySavingReferUrl: PropTypes.string,
};
