import classnames from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

class Radio extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.selected,
		};

		this.handleSelectionChange = this.handleSelectionChange.bind(this);
	}

	static getDerivedStateFromProps(props) {
		return {
			selected: props.selected,
		};
	}

	handleSelectionChange(e) {
		this.setState({
			selected: e.target.checked,
		});

		if (this.props.onChange) {
			this.props.onChange(e.target.checked);
		}

		if (this.props.onSelect && e.target.checked) {
			this.props.onSelect(e);
		} else if (this.props.onDeselect && !e.target.checked) {
			this.props.onDeselect(e);
		}
	}

	render() {
		const { children } = this.props;
		const radioStyle = {};

		if (this.props.hideRadioButton) {
			radioStyle.display = "none";
		}

		const classNames = classnames("container-radio", this.props.horizontal ? "mr-6" : "");

		return (
			<label className={classNames}>
				<input
					type="radio"
					onChange={this.handleSelectionChange}
					name={this.props.rel}
					value={this.props.value}
					style={radioStyle}
					checked={this.state.selected}
					disabled={this.props.disabled}
				/>
				<span className="checkmark" />
				{this.props.dangerouslySetInnerHTML ? (
					<span dangerouslySetInnerHTML={{ __html: this.props.label }} />
				) : this.props.inline ? (
					children
				) : (
					this.props.label
				)}
			</label>
		);
	}
}

export default Radio;

Radio.propTypes = {
	children: PropTypes.node,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
	selected: PropTypes.bool,
	onChange: PropTypes.func,
	onSelect: PropTypes.func,
	onDeselect: PropTypes.func,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	inline: PropTypes.bool,
	hideRadioButton: PropTypes.bool,
	rel: PropTypes.string,
	horizontal: PropTypes.bool,
	dangerouslySetInnerHTML: PropTypes.bool,
};
Radio.defaultProps = {
	horizontal: false,
	dangerouslySetInnerHTML: false,
};
