import classnames from "classnames";
import PropTypes from "prop-types";
import { Children, cloneElement, Component } from "react";

class RadioGroup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedValue: this.props.value,
		};

		this.handleSelectionChange = this.handleSelectionChange.bind(this);
	}

	handleSelectionChange(selected, value) {
		if (selected) {
			this.setState({
				selectedValue: value,
			});

			if (this.props.onChange) {
				this.props.onChange(value);
			}
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.value !== state.selectedValue) {
			return {
				...state,
				selectedValue: props.value,
			};
		} else {
			return state;
		}
	}

	render() {
		const classNames = classnames(
			"radio-group",
			`radio-group-${this.props.name}`,
			this.props.horizontal ? "flex items-start" : "",
		);

		return (
			<>
				{this.props.label && (
					<label htmlFor={this.props.name}>
						{this.props.label}
						{this.props.required && <span className="text-orange"> *</span>}
					</label>
				)}
				<div className={classNames}>
					{Children.map(this.props.children, (child, index) => {
						if (!child) {
							return child; // if it's null or false, just use the normal procedure
						}

						const value = typeof child.props.value !== "undefined" ? child.props.value : index;
						return cloneElement(child, {
							key: value,
							value,
							rel: this.props.name,
							onChange: selected => this.handleSelectionChange(selected, value),
							selected: value === this.state.selectedValue,
							disabled: this.props.disabled ? this.props.disabled : child.props.disabled,
							horizontal: this.props.horizontal,
						});
					})}
				</div>
			</>
		);
	}
}

export default RadioGroup;

RadioGroup.propTypes = {
	name: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	horizontal: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
	label: PropTypes.string,
	required: PropTypes.bool,
};
RadioGroup.defaultProps = {
	horizontal: false,
	label: null,
	required: false,
};
