import PropTypes from "prop-types";
import { useContext } from "react";
import LinkedFieldQuestion from "./questions/LinkedFieldQuestion";
import MatrixQuestion from "./questions/MatrixQuestion";
import OpenQuestion from "./questions/OpenQuestion";
import Information from "./questions/Information";
import NumericQuestion from "./questions/NumericQuestion";
import SelectQuestion from "./questions/SelectQuestion";
import MultipleChoiceQuestion from "./questions/MultipleChoiceQuestion";
import MultipleChoiceMultiQuestion from "./questions/MultipleChoiceMultiQuestion";
import QuestionnaireContext from "./QuestionnaireContext";
import filter from "lodash/filter";

export default function Question(props) {
	const context = useContext(QuestionnaireContext);

	const { questionnaire, answers } = context;
	const disabled = context.disabled || !props.question.active;

	const getAnswerValueForQuestion = () => {
		const filteredAnswers = filter(answers, ["question_id", props.question.id]);
		const answersForQuestion = filteredAnswers ? filteredAnswers : [];

		if (answersForQuestion.length > 0) {
			switch (props.question.type) {
				case questionnaire.question_types.MC_OPTIONS_SINGLE.key:
				case questionnaire.question_types.MC_OPTIONS_MULTI.key:
				case questionnaire.question_types.MATRIX.key:
					return answersForQuestion[0].answer_options;
				case questionnaire.question_types.OPEN_NUMERIC.key:
					return answersForQuestion[0].numeric_open_content;
				case questionnaire.question_types.MC_SELECT.key:
					if (answersForQuestion[0].answer_options.length > 0) {
						return answersForQuestion[0].answer_options[0];
					}
					break;
				default:
					return answersForQuestion[0].open_content;
			}
		}
		return null;
	};

	if (!props.question.visible) {
		return <span />;
	}

	const answer = getAnswerValueForQuestion();
	if (!props.question.active && (answer === null || answer === "" || (Array.isArray(answer) && answer.length === 0))) {
		return <span />;
	}

	switch (props.question.type) {
		case questionnaire.question_types.OPEN_SINGLE_LINE.key:
			return <OpenQuestion disabled={disabled} question={props.question} open_content={answer} />;
		case questionnaire.question_types.OPEN_MULTI_LINE.key:
			return <OpenQuestion disabled={disabled} question={props.question} open_content={answer} multiline />;
		case questionnaire.question_types.OPEN_NUMERIC.key:
			return <NumericQuestion disabled={disabled} question={props.question} numeric_open_content={answer} />;
		case questionnaire.question_types.MC_SELECT.key:
			return <SelectQuestion disabled={disabled} question={props.question} answer_option_id={answer} />;
		case questionnaire.question_types.MC_OPTIONS_SINGLE.key:
			return <MultipleChoiceQuestion disabled={disabled} question={props.question} selected_answer_options={answer} />;
		case questionnaire.question_types.MC_OPTIONS_MULTI.key:
			return (
				<MultipleChoiceMultiQuestion disabled={disabled} question={props.question} selected_answer_options={answer} />
			);
		case questionnaire.question_types.INFORMATION.key:
			return <Information question={props.question} />;
		case questionnaire.question_types.LINKED_FIELD.key:
			return <LinkedFieldQuestion disabled={disabled} question={props.question} open_content={answer} />;
		case questionnaire.question_types.MATRIX.key:
			return <MatrixQuestion disabled={disabled} question={props.question} selected_answer_options={answer} />;
		default:
			return <span>Unknown question</span>;
	}
}

Question.propTypes = {
	question: PropTypes.object,
};
