import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import useInputHandler from "../../hooks/useInputHandler";
import Checkmark from "../general/form/checkmark/Checkmark";

export default function InterestCheckmark(props) {
	const [checked, setChecked] = useState(props.checked);
	const [interest, SetInterest] = useState(props.interest);

	useEffect(() => {
		if (interest.requires_explanation) {
			props.textualChange(interest);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [interest]);

	const inputHandler = useInputHandler(SetInterest);

	const handleInterestChange = () => {
		setChecked(!checked);
		props.onChange(interest);
	};

	return (
		<>
			{props.disabled ? (
				checked && (
					<Checkmark
						label={interest.name}
						checked={checked}
						disabled={props.disabled}
						onChange={handleInterestChange}
					/>
				)
			) : (
				<Checkmark label={interest.name} checked={checked} onChange={handleInterestChange} />
			)}

			{interest.requires_explanation && checked && (
				<textarea
					value={interest.explanation !== null && interest.requires_explanation ? interest.explanation : ""}
					onChange={inputHandler}
					placeholder={interest.explanation_description}
					name="explanation"
					autoComplete="off"
					disabled={props.disabled}
				/>
			)}
		</>
	);
}

InterestCheckmark.propTypes = {
	interest: PropTypes.object,
	onChange: PropTypes.func,
	textualChange: PropTypes.func,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
};

InterestCheckmark.defaultProps = {
	disabled: false,
};
