import PropTypes from "prop-types";
import RDatePicker, { registerLocale } from "react-datepicker";
import { uctrans } from "../../../lib/Translator";
import nl from "date-fns/locale/nl";
registerLocale("nl", nl);

export default function DatePicker(props) {
	return (
		<RDatePicker
			selected={props.initialValue ? new Date(props.initialValue) : null}
			onChange={props.onChange}
			locale="nl"
			dateFormat={props.showTimeSelect ? "dd-MM-y HH:mm" : "dd-MM-y"}
			placeholderText={props.showTimeSelect ? uctrans("general.select_date_time") : uctrans("general.select_date")}
			timeFormat="HH:mm"
			timeCaption={uctrans("general.time")}
			showMonthDropdown
			showYearDropdown
			showTimeSelect={props.showTimeSelect}
			todayButton={props.disableTodayButton ? null : uctrans("general.today")}
			dropdownMode="select"
			openToDate={props.openToDate}
			maxDate={props.maxDate}
			minDate={props.minDate}
			disabled={props.disabled}
			calendarStartDay={1} // start monday
			isClearable={!props.disabled && props.showClearButton}
		/>
	);
}

DatePicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	initialValue: PropTypes.instanceOf(Date),
	disableTodayButton: PropTypes.bool,
	showTimeSelect: PropTypes.bool,
	openToDate: PropTypes.instanceOf(Date),
	maxDate: PropTypes.instanceOf(Date),
	minDate: PropTypes.instanceOf(Date),
	showClearButton: PropTypes.bool,
	disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
	disableTodayButton: false,
	showTimeSelect: false,
	showClearButton: false,
	disabled: false,
};
