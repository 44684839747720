import find from "lodash/find";
import { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import Select from "../../../../../js/react/components/general/Select";
import QuestionnaireContext from "../QuestionnaireContext";

export default function SoftwarePackageQuestion(props) {
	const [initialAnswer] = useState(
		props.open_content && props.open_content.includes(";") ? props.open_content.split(";") : props.open_content,
	);
	const [selectedSoftwarePackage, setSelectedSoftwarePackage] = useState(
		Array.isArray(initialAnswer) ? initialAnswer[0] : initialAnswer,
	);
	const [extraAnswer, setExtraAnswer] = useState(Array.isArray(initialAnswer) ? initialAnswer[1] : null);
	const { setAnswer, replaceTags, showColorsAndAdministration, colors } = useContext(QuestionnaireContext);

	const softwarePackageOptions = props.softwarePackages.map(softwarePackage => ({
		value: `${softwarePackage.id}`,
		label: `${softwarePackage.name}`,
	}));

	const currentSelectedSoftwarePackage = props.softwarePackages.find(
		softwarePackage => `${softwarePackage.id}` === `${selectedSoftwarePackage}`,
	);

	useEffect(() => {
		if (selectedSoftwarePackage == null) {
			setExtraAnswer(null);
		}

		if (
			!showColorsAndAdministration &&
			currentSelectedSoftwarePackage &&
			currentSelectedSoftwarePackage.open_title &&
			(extraAnswer == null || extraAnswer === "")
		) {
			setAnswer(props.question, "");
		} else {
			setAnswer(props.question, selectedSoftwarePackage || "");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSoftwarePackage]);

	useEffect(() => {
		if (props.open_content && props.question && props.open_content.includes(";")) {
			setAnswer(props.question, props.open_content);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSetAnswer = event => {
		setExtraAnswer(event.target.value);
		setAnswer(props.question, `${selectedSoftwarePackage};${event.target.value}`);
	};

	const colorCode = () => {
		if (currentSelectedSoftwarePackage) {
			switch (Number(currentSelectedSoftwarePackage.color_code)) {
				case colors.GREEN.key:
					return <span style={{ color: "green" }}>({uctrans("cooperation_check.color_codes.green")})</span>;
				case colors.RED.key:
					return <span style={{ color: "red" }}>({uctrans("cooperation_check.color_codes.red")})</span>;
				case colors.ORANGE.key:
					return <span style={{ color: "orange" }}>({uctrans("cooperation_check.color_codes.orange")})</span>;
				default:
					return "";
			}
		}
	};

	return (
		<>
			<div className="container mb-4">
				<div className="flex md:flex-row">
					<label>
						<span dangerouslySetInnerHTML={{ __html: replaceTags(props.question.content) }} />{" "}
						{props.question.required && <span className="text-orange">*</span>}
					</label>
				</div>
				{showColorsAndAdministration && colorCode()}
				<div className="md:flex-row">
					<Select
						disabled={props.disabled}
						placeholder={uctrans("general.choose_answer")}
						value={find(softwarePackageOptions, ["value", selectedSoftwarePackage])}
						options={softwarePackageOptions}
						name={`question-mc-select-${props.question.id}`}
						onChange={selectedValue => {
							setSelectedSoftwarePackage(selectedValue ? selectedValue.value : null);
						}}
						isClearable
					/>
				</div>
			</div>
			{currentSelectedSoftwarePackage && currentSelectedSoftwarePackage.open_title && (
				<div className="container mb-4">
					<div className="flex md:flex-row">
						<label>
							<span>{currentSelectedSoftwarePackage.open_title}</span>
							<span className="text-orange">*</span>
						</label>
					</div>
					<div className="md:flex-row">
						<input
							disabled={props.disabled}
							type="text"
							name="extra_answer"
							value={extraAnswer || ""}
							onChange={handleSetAnswer}
						/>
					</div>
				</div>
			)}
		</>
	);
}

SoftwarePackageQuestion.propTypes = {
	question: PropTypes.object,
	softwarePackages: PropTypes.array,
	open_content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	disabled: PropTypes.bool,
};

SoftwarePackageQuestion.defaultProps = {
	disabled: false,
	multiline: false,
	open_content: "",
};
