import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { route } from "../../../helpers";
import LeadIcon from "./LeadIcon";

export default function LeadOverviewItem({ lead, onClick, fromCrm }) {
	const leadSettings = window.data.lead_settings;

	const getTagReplaceText = lead => {
		if (lead.energylabel_final) {
			return lead.energylabel;
		}

		return `${lead.energylabel} (niet definitief)`;
	};

	const settings = useMemo(() => {
		let tooltipText = "";
		if (lead) {
			switch (lead.type) {
				case "sustainability":
					if (
						leadSettings.sustainability_energylabels &&
						leadSettings.sustainability_energylabels.includes(lead.energylabel.toLowerCase())
					) {
						tooltipText = leadSettings.sustainability_tooltip_text;
					} else if (
						leadSettings.sustainability_2_energylabels &&
						leadSettings.sustainability_2_energylabels.includes(lead.energylabel.toLowerCase())
					) {
						tooltipText = leadSettings.sustainability_2_tooltip_text;
					}

					return {
						in_use: !!leadSettings.use_sustainability,
						tooltip_text: tooltipText.replace("{energylabel}", getTagReplaceText(lead)),
					};
				case "seniors_overvalue":
					return {
						in_use: !!leadSettings.use_seniors_overvalue,
						tooltip_text: leadSettings.seniors_overvalue_tooltip_text,
						icon_idtoken: leadSettings.seniors_overvalue_icon.id_token,
					};
				case "moving_renovating":
					return {
						in_use: !!leadSettings.use_moving_renovating,
						tooltip_text: leadSettings.moving_renovating_tooltip_text,
						icon_idtoken: leadSettings.moving_renovating_icon.id_token,
					};
				case "interest_renewal":
					return {
						in_use: !!leadSettings.use_interest_renewal,
						tooltip_text: leadSettings.interest_renewal_tooltip_text,
						icon_idtoken: leadSettings.interest_renewal_icon.id_token,
					};
				default:
					return false;
			}
		}
		return null;
	}, [leadSettings, lead]);

	return (
		settings.in_use && (
			<a onClick={onClick}>
				<Tippy
					placement="top"
					arrow
					animation="perspective"
					duration="400"
					content={<div dangerouslySetInnerHTML={{ __html: settings.tooltip_text }} />}>
					<span>
						<LeadIcon
							status={lead.status}
							type={lead.type}
							energylabel={lead.energylabel}
							energylabelFinal={lead.energylabel_final}
							iconUrl={
								settings.icon_idtoken
									? route(`${fromCrm ? "crm" : "website"}.files.public-download`, { idtoken: settings.icon_idtoken })
									: undefined
							}
						/>
					</span>
				</Tippy>
			</a>
		)
	);
}

LeadOverviewItem.propTypes = {
	lead: PropTypes.shape({
		id: PropTypes.number,
		type: PropTypes.string,
		energylabel: PropTypes.string,
		energylabel_final: PropTypes.bool,
		status: PropTypes.string,
		comments: PropTypes.string,
		created_at: PropTypes.string,
	}),
	onClick: PropTypes.func.isRequired,
	fromCrm: PropTypes.bool,
};

LeadOverviewItem.defaultProps = {
	fromCrm: true,
};
