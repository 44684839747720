import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import head from "lodash/head";
import merge from "lodash/merge";
import remove from "lodash/remove";
import { renderToString } from "react-dom/server";
import { trans } from "./lib/Translator";

const checkQuestionDependency = (question, answers, dependency_types) => {
	if (question.dependency_questions) {
		if (question.dependency_type === dependency_types.AND) {
			const dependencyMissing = question.dependency_questions.some(dependencyQuestion => {
				if (answers && answers.length === 0) {
					return true;
				}
				const answerToDependencyQuestion = find(answers, ["question_id", dependencyQuestion.id]);
				if (!answerToDependencyQuestion) {
					return true;
				} else {
					if (!answerToDependencyQuestion.answer_options || answerToDependencyQuestion.answer_options.length === 0) {
						return true;
					}
					const requiredAnswerOptionByDependency = dependencyQuestion.selected_answer_options.map(
						selectedAnswerOption => selectedAnswerOption.id,
					);
					const aSymmetricDifference = requiredAnswerOptionByDependency.filter(
						x => !answerToDependencyQuestion.answer_options.includes(x),
					);
					if (aSymmetricDifference.length !== 0) {
						return true;
					}
				}
				return false;
			});
			question.visible = !dependencyMissing;
		} else if (question.dependency_type === dependency_types.OR) {
			question.visible = question.dependency_questions.some(dependencyQuestion => {
				if (answers && answers.length === 0) {
					return false;
				}

				const answerToDependencyQuestion = find(answers, ["question_id", dependencyQuestion.id]);

				if (!answerToDependencyQuestion) {
					return false;
				} else {
					if (!answerToDependencyQuestion.answer_options || answerToDependencyQuestion.answer_options.length === 0) {
						return false;
					}
					const requiredAnswerOptionByDependency = dependencyQuestion.selected_answer_options.map(
						selectedAnswerOption => selectedAnswerOption.id,
					);
					const intersection = requiredAnswerOptionByDependency.filter(x =>
						answerToDependencyQuestion.answer_options.includes(x),
					);
					if (intersection.length > 0) {
						return true;
					}
				}
				return false;
			});
		}
	} else {
		question.visible = true;
	}
};

const removeAnswerLogic = (answers, question, value, questionTypes) => {
	const existingAnswer = find(answers, ["question_id", question.id]);
	if (!existingAnswer) {
		return answers;
	}
	if (
		containsOnlyOneAnswer(question.type, questionTypes) ||
		(existingAnswer && existingAnswer.answer_options instanceof Array && existingAnswer.answer_options.length === 1)
	) {
		remove(answers, answer => answer.question_id === question.id);
	} else if (question.type === questionTypes.MATRIX.key && value === null) {
		remove(answers, answer => answer.question_id === question.id);
	} else {
		if (existingAnswer.answer_options instanceof Array) {
			remove(existingAnswer.answer_options, selected_answer_option => selected_answer_option === value);
		}
	}
	return answers;
};

const setAnswerLogic = (answers, question, value, questionTypes) => {
	const newAnswer = valueToAnswer(question, value, questionTypes);
	if (containsOnlyOneAnswer(question.type, questionTypes)) {
		const existingAnswer = find(answers, ["question_id", question.id]);
		if (existingAnswer) {
			const existingAnswerCopy = cloneDeep(existingAnswer);
			merge(existingAnswerCopy, newAnswer);
			const existingAnswerIndex = findIndex(answers, ["question_id", question.id]);
			answers.splice(existingAnswerIndex, 1, existingAnswerCopy);
		} else {
			answers = [...answers, newAnswer];
		}
	} else {
		const existingAnswer = find(answers, ["question_id", newAnswer.question_id]);
		if (existingAnswer) {
			if (question.type === questionTypes.MATRIX.key) {
				// check if answer already contains an answer for this row, else replace
				remove(
					existingAnswer.answer_options,
					selectedAnswerOption => selectedAnswerOption.questionRowId === value.questionRowId,
				);
			}
			if (existingAnswer.answer_options instanceof Array) {
				existingAnswer.answer_options.push(head(newAnswer.answer_options));
			} else {
				console.warn("answer_options should always be filled for questions with an existing answer");
			}
		} else {
			answers = [...answers, newAnswer];
		}
	}
	return answers;
};

const contactpersonTagText = contactperson => {
	let text = "";
	if (contactperson) {
		const fields = ["name", "email", "website_role"];
		fields.forEach((field, index, array) => {
			if (contactperson.hasOwnProperty(field) && contactperson[field] && contactperson[field] !== "") {
				text += contactperson[field];
			} else {
				text += "onbekend";
			}

			if (index !== array.length - 1) {
				text += " - ";
			}
		});
	}
	return text;
};

const replaceTags = (content, questionnaire_tags) => {
	const matches = content.match(/\{.*?\}/g);

	if (matches !== null) {
		// get first match only
		const tagToReplace = find(questionnaire_tags, (tag, key) => key === matches[0].substring(1, matches[0].length - 1));

		if (typeof tagToReplace !== "undefined") {
			if (Array.isArray(tagToReplace)) {
				if (matches[0].includes("CONTACTPERSONEN")) {
					let html = "<ul>";
					tagToReplace.map((tag, index) => (html += renderToString(<li key={index}>{contactpersonTagText(tag)}</li>)));
					html = `${html}</ul>`;
					return content.replace(matches[0], html);
				}
			} else if (tagToReplace !== null) {
				return content.replace(matches[0], tagToReplace);
			} else {
				return content.replace(matches[0], trans("companies.tag_unknown"));
			}
		}
	}
	return content;
};

const valueToAnswer = (question, value, questionTypes) => {
	let newAnswer = {};
	if (
		question.type === questionTypes.OPEN_SINGLE_LINE.key ||
		question.type === questionTypes.OPEN_MULTI_LINE.key ||
		question.type === questionTypes.LINKED_FIELD.key
	) {
		newAnswer = { question_id: question.id, open_content: value };
	} else if (question.type === questionTypes.OPEN_NUMERIC.key) {
		newAnswer = { question_id: question.id, numeric_open_content: value };
	} else if (
		question.type === questionTypes.MC_SELECT.key ||
		question.type === questionTypes.MC_OPTIONS_SINGLE.key ||
		question.type === questionTypes.MC_OPTIONS_MULTI.key ||
		question.type === questionTypes.MATRIX.key
	) {
		newAnswer = { question_id: question.id, answer_options: [value] };
	} else {
		console.warn("Question type not recognized, open_content used for now");
		newAnswer = { question_id: question.id, open_content: value };
	}
	return newAnswer;
};

const containsOnlyOneAnswer = (type, questionTypes) =>
	type === questionTypes.OPEN_SINGLE_LINE.key ||
	type === questionTypes.LINKED_FIELD.key ||
	type === questionTypes.OPEN_MULTI_LINE.key ||
	type === questionTypes.OPEN_NUMERIC.key ||
	type === questionTypes.MC_SELECT.key ||
	type === questionTypes.MC_OPTIONS_SINGLE.key;

const typeHasAnswerOptions = (type, questionTypes) =>
	type === questionTypes.MC_SELECT.key ||
	type === questionTypes.MC_OPTIONS_SINGLE.key ||
	type === questionTypes.MC_OPTIONS_MULTI.key ||
	type === questionTypes.MATRIX.key;

export {
	removeAnswerLogic,
	setAnswerLogic,
	replaceTags,
	valueToAnswer,
	containsOnlyOneAnswer,
	typeHasAnswerOptions,
	checkQuestionDependency,
	contactpersonTagText,
};
