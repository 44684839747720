import { useContext } from "react";
import PropTypes from "prop-types";
import find from "lodash/find";
import { uctrans } from "../../../../../js/lib/Translator";
import QuestionnaireContext from "../QuestionnaireContext";

export default function MatrixQuestion(props) {
	const { setAnswer, removeAnswer, replaceTags, showColorsAndAdministration, answerOptionWithColorCode } =
		useContext(QuestionnaireContext);

	const handleSetAnswer = (answerOptionId, questionRowId, newSelectedState) => {
		if (newSelectedState) {
			setAnswer(props.question, { answerOptionId, questionRowId });
		} else {
			removeAnswer(props.question, { answerOptionId, questionRowId });
		}
	};

	const answerOptionIsSelected = (answerOptionId, questionRowId) => {
		if (!props.selected_answer_options || props.selected_answer_options.length < 0) {
			return false;
		}

		return find(
			props.selected_answer_options,
			selectedAnswerOption =>
				selectedAnswerOption.answerOptionId === answerOptionId && selectedAnswerOption.questionRowId === questionRowId,
		)
			? "checked"
			: "";
	};

	const optionText = answer_option => {
		if (showColorsAndAdministration) {
			return (
				<>
					{replaceTags(answer_option.content)} {answerOptionWithColorCode(answer_option)}{" "}
					{answer_option.administrative && (
						<span style={{ color: "blue" }}>({uctrans("cooperation_check.annually.needs_administration")})</span>
					)}
				</>
			);
		}

		return replaceTags(answer_option.content);
	};

	return (
		<div className="my-4">
			<div>
				<label>
					<span className="question-title" dangerouslySetInnerHTML={{ __html: replaceTags(props.question.content) }} />{" "}
					{props.question.required && <span className="text-orange">*</span>}
				</label>
			</div>
			{props.question.description && (
				<div className="mb-5">
					<p dangerouslySetInnerHTML={{ __html: replaceTags(props.question.description) }} />
				</div>
			)}
			<table className="table matrix-table">
				<tbody>
					<tr className="bg-grey-light">
						<th className="w-48">&nbsp;</th>
						{props.question.answer_options.map(({ ...answer_option }) => (
							<th key={answer_option.id}>
								<label>{optionText(answer_option)}</label>
							</th>
						))}
					</tr>
					{props.question.question_rows.map(({ ...question_row }) => (
						<tr key={question_row.id}>
							<td className="w-48">{question_row.content}</td>
							{props.question.answer_options.map(({ ...answer_option }) => (
								<td key={`row${question_row.id}_${answer_option.id}`}>
									<label className="container-radio">
										<input
											disabled={props.disabled}
											type="radio"
											name={`question-mc-${question_row.id}-${answer_option.id}`}
											value={answer_option.id}
											checked={answerOptionIsSelected(answer_option.id, question_row.id)}
											onChange={() =>
												handleSetAnswer(
													answer_option.id,
													question_row.id,
													!answerOptionIsSelected(answer_option.id, question_row.id),
												)
											}
										/>
										<div className="checkmark" style={{ position: "relative" }} />
									</label>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

MatrixQuestion.propTypes = {
	question: PropTypes.object,
	selected_answer_options: PropTypes.array,
	disabled: PropTypes.bool,
};

MatrixQuestion.defaultProps = {
	disabled: false,
	selected_answer_options: [],
};
