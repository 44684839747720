import { createContext } from "react";

export const OverviewContext = createContext({
	data: {
		items: [],
		metadata: {
			filterdata: {},
			filtering: {
				showCollapsible: false,
				filterRules: [],
				filters: [],
			},
			pagination: {
				limit: null,
				page: null,
				page_count: null,
				total_count: null,
			},
			sorting: {
				active_column: null,
				active_order: null,
				allowedColumns: [],
			},
		},
	},
	loading: true,
	loadData: () => {
		console.error("No provider for OverviewContext found.");
	},
	setState: () => {
		console.error("No provider for OverviewContext found.");
	},
	getParameters: () => {
		console.error("No provider for OverviewContext found.");
	},
	getActiveFilterCount: () => {
		console.error("No provider for OverviewContext found.");
		return 0;
	},
});
