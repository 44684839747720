import { useContext } from "react";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import QuestionnaireContext from "../QuestionnaireContext";

export default function MultipleChoiceMultiQuestion(props) {
	const {
		setAnswer,
		removeAnswer,
		replaceTags,
		showColorsAndAdministration,
		answerOptionWithColorCode,
		horizontalAnswerOptions,
	} = useContext(QuestionnaireContext);

	const handleSetAnswer = (answerOptionId, currentSelectedState) => {
		if (!currentSelectedState) {
			setAnswer(props.question, answerOptionId);
		} else {
			removeAnswer(props.question, answerOptionId);
		}
	};

	const answerOptionIsSelected = answer_option_id => {
		if (!props.selected_answer_options || props.selected_answer_options.length < 0) {
			return false;
		}
		return props.selected_answer_options.indexOf(answer_option_id) !== -1 ? "checked" : "";
	};

	const optionText = answer_option => {
		if (showColorsAndAdministration) {
			return (
				<>
					{replaceTags(answer_option.content)} {answerOptionWithColorCode(answer_option)}{" "}
					{answer_option.administrative && (
						<span style={{ color: "blue" }}>({uctrans("cooperation_check.annually.needs_administration")})</span>
					)}
				</>
			);
		}

		return replaceTags(answer_option.content);
	};

	return (
		<div className={`${horizontalAnswerOptions ? "flex mb-2" : "mb-8"}`}>
			<div className={`${horizontalAnswerOptions ? "w-32 pt-2" : ""}`}>
				<label>
					<span className="question-title" dangerouslySetInnerHTML={{ __html: replaceTags(props.question.content) }} />{" "}
					{props.question.required && <span className="text-orange">*</span>}
				</label>
			</div>
			{props.question.description && (
				<div className="mb-5">
					<p dangerouslySetInnerHTML={{ __html: replaceTags(props.question.description) }} />
				</div>
			)}
			<div className={`${horizontalAnswerOptions ? "flex-1 flex flex-wrap ml-8" : ""}`}>
				{props.question.answer_options.map(({ ...answer_option }) => (
					<div key={answer_option.id} className="mr-4">
						<label className="container-checkbox">
							{optionText(answer_option)}
							<input
								disabled={props.disabled}
								type="checkbox"
								name={`question-mc-${answer_option.id}`}
								onChange={() => handleSetAnswer(answer_option.id, answerOptionIsSelected(answer_option.id))}
								checked={answerOptionIsSelected(answer_option.id)}
							/>
							<span className="checkmark" />
						</label>
					</div>
				))}
			</div>
		</div>
	);
}

MultipleChoiceMultiQuestion.propTypes = {
	question: PropTypes.object,
	selected_answer_options: PropTypes.array,
	disabled: PropTypes.bool,
};

MultipleChoiceMultiQuestion.defaultProps = {
	disabled: false,
	selected_answer_options: [],
};
