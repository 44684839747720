import PropTypes from "prop-types";
import { lazy, Suspense } from "react";
import Spinner from "../../../../../../js/react/components/general/Spinner";

const TextEditorComponent = lazy(async () => import(/* webpackChunkName: "tinymce-editor" */ "./TextEditorComponent"));

export default function TextEditor(props) {
	return (
		<Suspense
			fallback={
				<div className="text-left flex-grow mt-2">
					<Spinner width={25} />
				</div>
			}>
			<TextEditorComponent
				height={props.height}
				value={props.value}
				onChange={props.onChange}
				type={props.type}
				disabled={props.disabled}
				overrideConfig={props.overrideConfig}
				hideToolbar={props.hideToolbar}
				autoResize={props.autoResize}
			/>
		</Suspense>
	);
}
TextEditor.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	height: PropTypes.number,
	overrideConfig: PropTypes.object,
	hideToolbar: PropTypes.bool,
	autoResize: PropTypes.bool,
};

TextEditor.defaultProps = {
	type: "website",
	disabled: false,
	overrideConfig: {},
	hideToolbar: false,
	autoResize: false,
};
