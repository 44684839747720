import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { uctrans } from "../../../../lib/Translator";
import EvaIcon from "../EvaIcon";
import Tippy from "@tippyjs/react";
import Checkmark from "./checkmark/Checkmark";

export default function LabeledCheckbox(props) {
	const [checked, setChecked] = useState(props.checked);
	const handleFormChange = useCallback(
		selected => {
			setChecked(!checked);
			props.onChange(selected);
		},
		[props, checked],
	);

	useEffect(() => {
		setChecked(props.checked);
	}, [props.checked]);

	return (
		<>
			<label
				className="text-green-dark font-medium text-base pb-1"
				htmlFor={typeof props.id === "undefined" ? props.name : props.id}>
				{props.translateLabel ? uctrans(props.label) : props.label}{" "}
				{props.hint && (
					<Tippy placement="auto" arrow animation="perspective" content={props.hint}>
						<span className="ml-2">
							<EvaIcon type="info" fill="#249286" height="17" width="17" />
						</span>
					</Tippy>
				)}
			</label>
			<Checkmark label={props.placeholder} onChange={handleFormChange} name={props.name} checked={checked} />
		</>
	);
}

LabeledCheckbox.propTypes = {
	name: PropTypes.string,
	translateLabel: PropTypes.bool,
	hint: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
};
