export default {
	lang: {
		months: [
			"januari",
			"februari",
			"maart",
			"april",
			"mei",
			"juni",
			"juli",
			"augustus",
			"september",
			"oktober",
			"november",
			"december",
		],
		weekdays: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
		shortMonths: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
		thousandsSep: ".",
		decimalSep: ",",
	},
	colors: [
		"#009286",
		"#F3C000",
		"#FF6600",
		"#94C23C",
		"#005E5D",
		"#364349",
		"#006480",
		"#0067B2",
		"#00ABE9",
		"#C2DB91",
		"#709B9A",
		"#90979B",
		"#42ACA3",
		"#FADF7A",
		"#FBA972",
		"#7197A2",
		"#80CCE8",
		"#6095BB",
		"#E8FFBC",
		"#BFD7D7",
		"#D6DCDE",
		"#9DD5D1",
		"#FFF3C5",
		"#CDE4EA",
		"#E3F8FF",
		"#C6DFF1",
	],
	navigation: {
		menuStyle: {
			padding: 0,
			border: 0,
		},
		menuItemStyle: {
			color: "#ffffff",
			background: "#01b6ed",
		},
		menuItemHoverStyle: {
			color: "#ffffff",
			background: "#0199d0",
		},
	},
	exporting: {
		enabled: false,
	},
	credits: {
		enabled: false,
	},
	chart: {
		style: {
			fontFamily:
				"Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
			color: "#31353e",
		},
	},
};
