const renderInputProps = (externalProps, internalProps, files) => {
	const inputProps = { ...externalProps };
	inputProps.onClick = null;
	inputProps.multiple = inputProps.maxFiles === 0 || files.length < inputProps.maxFiles - 1;
	if (inputProps.acceptedExtensions) {
		inputProps.accept = inputProps.acceptedExtensions
			.map(extension => (extension.charAt(0) === "." ? extension : `.${extension}`))
			.join(",");
	}
	inputProps.disabled = internalProps.disabled;
	return inputProps;
};

const renderRootProps = (externalProps, internalProps) => {
	const rootProps = { ...externalProps };
	rootProps.onClick = null;
	rootProps.disabled = internalProps.disabled;
	return rootProps;
};

export { renderInputProps, renderRootProps };
