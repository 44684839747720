import { createLoader } from "../general/notifications";
import axios from "axios";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { route } from "../../../helpers";
import { uctrans } from "../../../lib/Translator";
import DeleteDialog from "../general/DeleteDialog";
import EvaIcon from "../general/EvaIcon";
import Translate from "../general/Translate";

export default function AppDeviceOverview(props) {
	const [devices, setDevices] = useState(props.devices);
	const [deregistering, setDeregistering] = useState(null);

	const handleDelete = useCallback(async () => {
		const deviceId = deregistering;
		const loader = createLoader(uctrans("general.:item_is_being_deleted", {}, { item: "app.device.singular" }));
		try {
			await axios.delete(route(props.deleteRoute, { ...props.deleteRouteParams, appdevice: deviceId }));
			const deletedIndex = devices.findIndex(device => device.id === deviceId);
			if (deletedIndex !== -1) {
				const newDevices = [...devices];
				newDevices.splice(deletedIndex, 1);
				setDevices(newDevices);
			}
			loader.success(uctrans("general.deleted_:item", {}, { item: "app.device.singular" }));
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
		setDeregistering(null);
	}, [deregistering, devices, props.deleteRoute, props.deleteRouteParams]);

	return (
		devices.length > 0 && (
			<div className="mt-2">
				{props.showText && (
					<strong>
						<Translate content="app.registered_devices" />
					</strong>
				)}
				<table className="table overview w-full">
					<thead>
						<tr>
							<th>
								<Translate content="app.device_fields.name" />
							</th>
							<th>
								<Translate content="app.device_fields.platform" />
							</th>
							<th>
								<Translate content="app.device_fields.registered" />
							</th>
							<th>
								<Translate content="app.device_fields.last_activity" />
							</th>
							<th />
						</tr>
					</thead>

					<tbody>
						{devices.map(device => (
							<tr key={device.id}>
								<td>{device.model}</td>
								<td>{device.platform}</td>
								<td>{device.created_at}</td>
								<td>{device.updated_at}</td>
								<td className="w-1/24">
									<a onClick={() => setDeregistering(device.id)}>
										<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<DeleteDialog
					isOpen={deregistering !== null}
					onCancel={() => setDeregistering(null)}
					onConfirm={handleDelete}
					deleteData={{
						message: uctrans("general.confirm_delete_:item", {}, { item: "app.device.singular" }),
						errors: [],
						warnings: [],
					}}
				/>
			</div>
		)
	);
}

AppDeviceOverview.propTypes = {
	devices: PropTypes.array.isRequired,
	deleteRoute: PropTypes.string.isRequired,
	deleteRouteParams: PropTypes.object.isRequired,
	showText: PropTypes.bool,
};
AppDeviceOverview.defaultProps = {
	showText: true,
};
