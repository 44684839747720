import PropTypes from "prop-types";
import includes from "lodash/includes";
import remove from "lodash/remove";
import Checkmark from "../form/checkmark/Checkmark";
import Filter from "./Filter";

class CheckboxMultiFilter extends Filter {
	handleCheckChange(context, value) {
		const data = { ...context.data };
		if (includes(data.metadata.filtering.filters[this.props.name], `${value}`)) {
			remove(data.metadata.filtering.filters[this.props.name], foundValue => `${foundValue}` === `${value}`);
		} else {
			if (data.metadata.filtering.filters[this.props.name]) {
				data.metadata.filtering.filters[this.props.name].push(`${value}`);
			} else {
				data.metadata.filtering.filters[this.props.name] = [`${value}`];
			}
		}
		context.setData(data);
	}

	getIfChecked(context, value) {
		return includes(context.data.metadata.filtering.filters[this.props.name], `${value}`);
	}

	handleAllCheckboxes(context, value) {
		const data = { ...context.data };
		if (value === null || value === false) {
			delete data.metadata.filtering.filters[this.props.name];
		} else {
			data.metadata.filtering.filters[this.props.name] = this.props.options.map(({ value }) => value);
		}
		context.setData(data);
	}

	renderAs(context) {
		return (
			<div className={this.props.className}>
				<div className="flex">
					{!!this.props.label && <label>{this.props.label}</label>}
					{this.props.useSelectAll && (
						<span className="flex block my-1 ml-2">
							<a onClick={() => this.handleAllCheckboxes(context, true)}>Alles aan</a>
							<span className="mx-1">|</span>
							<a onClick={() => this.handleAllCheckboxes(context, false)}>Alles uit</a>
						</span>
					)}
				</div>
				{this.props.options.map(option => (
					<div key={`${this.props.name}_${option.value}`} className={this.props.checkMarkClassName}>
						<Checkmark
							label={option.label}
							onChange={() => this.handleCheckChange(context, option.value)}
							name={this.props.name}
							checked={this.getIfChecked(context, option.value)}
							renderLabel={this.props.renderCheckmarkLabel ? () => this.props.renderCheckmarkLabel(option) : null}
						/>
					</div>
				))}
			</div>
		);
	}
}

export default CheckboxMultiFilter;

CheckboxMultiFilter.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	checkMarkClassName: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.array,
	renderCheckmarkLabel: PropTypes.func,
	useSelectAll: PropTypes.bool,
};
CheckboxMultiFilter.defaultProps = {
	className: "form-container",
	checkMarkClassName: "form-1-4",
	useSelectAll: false,
};
