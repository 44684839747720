import classNames from "classnames";
import PropTypes from "prop-types";
import SearchBar from "../SearchBar";
import Filter from "./Filter";

class TextFilter extends Filter {
	renderAs(context) {
		// Classname
		const className = classNames("overview-filter", this.props.className);

		return (
			<div className={className}>
				{this.props.label && <label htmlFor={this.props.name}>{this.props.label}</label>}
				<SearchBar
					name={this.props.name}
					placeholder={this.props.placeholder}
					value={context.data.metadata.filtering.filters[this.props.name]}
					onChange={e => this.handleChange(context, e)}
					hideIcon={this.props.hideIcon}
				/>
			</div>
		);
	}
}

export default TextFilter;
TextFilter.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	hideIcon: PropTypes.bool,
};
