import classnames from "classnames";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { uctrans } from "../../../lib/Translator";
import Dialog from "./Dialog";

export default function ConfirmDialog(props) {
	const [isOpen, setIsOpen] = useState(props.isOpen);

	const confirmButtonClassNames = classnames("button", "button-primary", {
		disabled: props.confirmIsDisabled,
	});

	const handleConfirm = useCallback(
		e => {
			props.onConfirm(e);
			setIsOpen(false);
		},
		[props],
	);

	const handleCancel = e => {
		if (props.onCancel) {
			props.onCancel(e);
		}

		setIsOpen(false);
	};

	const handleKeyPress = useCallback(
		e => {
			const key = e.which || e.keyCode;
			if (key === 13 && isOpen) {
				// Submit on enter
				handleConfirm(e);
			}
		},
		[isOpen, handleConfirm],
	);

	useEffect(() => {
		document.addEventListener("keypress", handleKeyPress);
		return () => document.removeEventListener("keypress", handleKeyPress);
	}, [handleKeyPress]);

	useEffect(() => {
		setIsOpen(props.isOpen);
	}, [props.isOpen]);

	return (
		<Dialog className={props.className} isOpen={isOpen} width={props.width} onClose={handleCancel} title={props.title}>
			<div className="whitespace-pre-wrap">{props.children}</div>

			<br />
			<div className="grid-x confirm-dialog-buttons">
				<a className={confirmButtonClassNames} onClick={!props.confirmIsDisabled ? handleConfirm : undefined}>
					{props.confirmButtonLabel}
				</a>
				<span>&nbsp;&nbsp;&nbsp;</span>
				{!props.hideCancelButton && (
					<a className="button link text-grey-darker" onClick={handleCancel}>
						{props.cancelButtonLabel}
					</a>
				)}
			</div>
		</Dialog>
	);
}

ConfirmDialog.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.string,
	cancelButtonLabel: PropTypes.string,
	confirmButtonLabel: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	isOpen: PropTypes.bool.isRequired,
	confirmIsDisabled: PropTypes.bool,
	hideCancelButton: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ConfirmDialog.defaultProps = {
	title: uctrans("general.are_you_sure"),
	confirmButtonLabel: uctrans("general.yes"),
	cancelButtonLabel: uctrans("general.no"),
	className: "confirm_dialog",
};
