import PropTypes from "prop-types";
import { Component } from "react";

class DebouncedTextarea extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value,
			bouncing: false,
		};

		this.ref = this.props.reference;
		this.timeoutIdentifier = null;

		this.handleChange = this.handleChange.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.bouncing) {
			return prevState;
		} else {
			return {
				...nextProps,
				bouncing: false,
			};
		}
	}

	handleChange(event) {
		this.setState({
			value: event.target.value,
			bouncing: true,
		});

		const copiedEvent = Object.assign({}, event);

		if (this.timeoutIdentifier) {
			clearTimeout(this.timeoutIdentifier);
			this.timeoutIdentifier = null;
		}

		this.timeoutIdentifier = setTimeout(() => {
			if (this.props.onChange) {
				this.props.onChange(copiedEvent);
			}

			this.setState({
				bouncing: false,
			});
		}, this.props.debounceTime);
	}

	render() {
		return (
			<div>
				<textarea
					ref={this.ref}
					name={this.props.name}
					placeholder={this.props.placeholder}
					className={this.props.className}
					value={this.state.value || ""}
					onChange={this.handleChange}
					disabled={this.props.disabled}
					autoComplete={this.props.autoComplete ? "on" : "off"}
				/>
			</div>
		);
	}
}

export default DebouncedTextarea;

DebouncedTextarea.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	debounceTime: PropTypes.number,
	reference: PropTypes.any,
	autoComplete: PropTypes.bool,
	disabled: PropTypes.bool,
};

DebouncedTextarea.defaultProps = {
	debounceTime: 400,
	autoComplete: false,
	disabled: false,
};
