import PropTypes from "prop-types";
import { uctrans } from "../../../../lib/Translator";
import Cleave from "cleave.js/react";

export default function InitialsTextInput(props) {
	return (
		<>
			<label htmlFor={typeof props.id === "undefined" ? props.name : props.id}>
				{props.translateLabel ? uctrans(props.label) : props.label}{" "}
				<span className="text-orange">{props.required ? "*" : ""}</span>
			</label>
			<Cleave
				options={{
					blocks: [1, 1, 1, 1, 1, 1, 1],
					delimiter: ".",
					uppercase: true,
				}}
				id={typeof props.id === "undefined" ? props.name : props.id}
				type={props.type}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
				autoComplete={props.autoComplete ? "on" : "off"}
				placeholder={props.placeholder}
			/>
		</>
	);
}

InitialsTextInput.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	translateLabel: PropTypes.bool,
	value: PropTypes.string.isRequired,
	autoComplete: PropTypes.bool,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	type: PropTypes.string,
	id: PropTypes.string,
	placeholder: PropTypes.string,
};

InitialsTextInput.defaultProps = {
	autoComplete: false,
	type: "text",
	disabled: false,
	translateLabel: true,
};
