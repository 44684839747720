import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { uctrans } from "../../../lib/Translator";
import findSelectedOption from "../../hooks/findSelectedOption";
import Dialog from "../general/Dialog";
import Select from "../general/Select";

export default function UnsubscribeReasonDialog(props) {
	const [selectedReason, setSelectedReason] = useState(null);

	useEffect(() => {
		setSelectedReason(findSelectedOption(props.unsubscribeReasons, selectedReason, true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={props.onCancel}
			width={850}
			title={uctrans("communication.newsletter_subscription_types.fields.unsubscribe_reason_id")}>
			<div className="w-full">
				<label>{uctrans("communication.newsletters.unsubscribe_reasons.guiding_text_dialog")}</label>
				<Select
					name="unsubscribe_reason_id"
					value={selectedReason}
					options={props.unsubscribeReasons}
					onChange={selectedValue => setSelectedReason(selectedValue)}
					placeholder={uctrans("communication.newsletter_subscription_types.fields.unsubscribe_reason_id")}
					isClearable={false}
				/>

				<div className="form-full">
					<div className="mt-6">
						<button
							type="button"
							className="button button-primary button-dialog"
							onClick={() => {
								props.onSelect(selectedReason ? selectedReason.value : null);
							}}>
							{uctrans("general.ok")}
						</button>
						<a className="ml-2" onClick={props.onCancel}>
							{uctrans("general.cancel")}
						</a>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

UnsubscribeReasonDialog.propTypes = {
	unsubscribeReasons: PropTypes.array,
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

UnsubscribeReasonDialog.defaultProps = {
	unsubscribeReasons: [],
};
