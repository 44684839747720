import axios from "axios";
import PropTypes from "prop-types";
import { Component } from "react";
import { uctrans } from "../../../../lib/Translator";
import DeleteDialog from "../DeleteDialog";
import OverviewExport from "../overview/OverviewExport";
import Overview from "../overview/Overview";
import { OverviewContext } from "../overview/overview-context";
import PageLimitSelect from "../overview/PageLimitSelect";
import Pagination from "../overview/Pagination";
import Spinner from "../Spinner";
import Translate from "../Translate";
import assign from "lodash/assign";

class AbnOverview extends Component {
	constructor(props) {
		super(props);

		const initialState = {
			dataSource: this.dataSource.bind(this),
		};

		if (props.initialData) {
			initialState.overviewData = props.initialData;
		} else {
			initialState.overviewData = null;
			this.fetchData();
		}

		this.state = initialState;
	}

	async fetchData() {
		const response = await this.dataSource(this);

		this.setState({
			overviewData: response,
		});
	}

	async dataSource(overview) {
		let params = overview.parameters;

		if (this.props.extraQuerystringParameters) {
			params = assign(overview.parameters, this.props.extraQuerystringParameters);
		}

		const response = await axios.get(this.props.indexUrl, {
			params: assign(params, this.props.indexUrlAdditionalParameters),
		});

		if (response.data && this.props.reloadedData) {
			this.props.reloadedData(response.data);
		}
		return response.data.overview;
	}

	renderAs(overview) {
		return (
			<div className="overview">
				{this.renderHeader(overview)}
				{this.renderControls(overview)}

				<div style={{ position: "relative" }}>{this.renderBody(overview)}</div>

				{overview.data.items.length === 0 && !overview.loading && <p>{this.renderNoResults(overview)}</p>}

				{this.renderFooter(overview)}
				{this.props.useExport && <OverviewExport exportUrls={this.props.exportUrls} />}
				{this.renderDeleteDialog(overview)}
			</div>
		);
	}

	render() {
		return (
			this.state.overviewData !== null && (
				<Overview
					initialData={this.state.overviewData}
					modelTranslations={this.props.modelTranslations}
					dataSource={this.state.dataSource}
					useDragAndDrop={this.props.useDragAndDrop}
					deleteRouteName={this.props.deleteRouteName}
					moveRouteName={this.props.moveRouteName}
					moveRouteAdditionalParameters={this.props.moveRouteAdditionalParameters}
					dataUpdatedAt={this.props.dataUpdatedAt}>
					<OverviewContext.Consumer>{overview => this.renderAs(overview)}</OverviewContext.Consumer>
				</Overview>
			)
		);
	}

	renderHeader(overview) {
		if (this.props.renderHeader) {
			return this.props.renderHeader(overview);
		} else {
			return (
				<OverviewContext.Consumer>
					{overview => (
						<div className="flex">
							<div className="float-left overview-filters flex-1">
								<div className="overview-filters-visible">{this.renderFilters(overview)}</div>
							</div>
						</div>
					)}
				</OverviewContext.Consumer>
			);
		}
	}

	renderNoResults(overview) {
		if (this.props.renderNoResults) {
			return this.props.renderNoResults(overview);
		} else {
			return (
				<Translate content="overview.no_:items_to_show" replaces={{ items: this.props.modelTranslations.plural }} />
			);
		}
	}

	renderFilters(overview) {
		if (this.props.renderFilters) {
			return this.props.renderFilters(overview);
		} else {
			return null;
		}
	}

	renderControls(overview) {
		if (this.props.renderControls) {
			return this.props.renderControls(overview);
		} else {
			return (
				<>
					{!this.props.useDragAndDrop && !this.props.hideControls && (
						<div className="overview-controls">
							<div className="overview-controls flex w-full justify-between">
								<div className="flex content-center flex-wrap ">
									<div className="flex">
										<Pagination />
									</div>
									<div className="overview-info flex mt-3">
										{overview.data.metadata.pagination.total_count === 1 ? (
											uctrans("overview.1_result")
										) : (
											<Translate
												content="overview.:count_results"
												replaces={{ count: overview.data.metadata.pagination.total_count }}
											/>
										)}
									</div>
									<div className={`ml-20 ${!overview.loading ? "hidden" : ""}`}>
										<Spinner width={25} />
									</div>
								</div>
								<div className="flex justify-end space-x-2">{this.props.usePageLimitSelect && <PageLimitSelect />}</div>
							</div>
						</div>
					)}
					{(this.props.useDragAndDrop || this.props.hideControls) && !this.props.hideNrOfResults && (
						<div className="overview-controls flex w-full justify-between">
							<div className="flex content-center flex-wrap ">
								<div className="overview-info">
									{overview.data.metadata.pagination.total_count === 1 ? (
										uctrans("overview.1_result")
									) : (
										<Translate
											content="overview.:count_results"
											replaces={{ count: overview.data.metadata.pagination.total_count }}
										/>
									)}
								</div>
								<div className={`ml-2 ${!overview.loading ? "hidden" : ""}`}>
									<Spinner width={20} />
								</div>
							</div>
						</div>
					)}
				</>
			);
		}
	}

	renderBody(overview) {
		if (this.props.renderBody) {
			return this.props.renderBody(overview);
		} else {
			return null;
		}
	}

	renderFooter(overview) {
		if (this.props.renderFooter) {
			return this.props.renderFooter(overview);
		} else {
			return null;
		}
	}

	renderDeleteDialog(overview) {
		if (this.props.renderDeleteDialog) {
			return this.props.renderDeleteDialog(overview);
		} else {
			return (
				<DeleteDialog
					onConfirm={() => overview.submitDelete()}
					onCancel={() => overview.toggleDeleteDialog()}
					isOpen={overview.dialogOpen}
					deleteData={overview.deleteData}
				/>
			);
		}
	}
}

export default AbnOverview;

AbnOverview.propTypes = {
	initialData: PropTypes.object,
	extraQuerystringParameters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	indexUrlAdditionalParameters: PropTypes.object,
	indexUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	renderNoResults: PropTypes.func,
	renderDeleteDialog: PropTypes.func,
	renderHeader: PropTypes.func,
	renderFilters: PropTypes.func,
	renderControls: PropTypes.func,
	renderBody: PropTypes.func.isRequired,
	renderFooter: PropTypes.func,
	modelTranslations: PropTypes.object,
	useDragAndDrop: PropTypes.bool,
	hideControls: PropTypes.bool,
	hideNrOfResults: PropTypes.bool,
	usePageLimitSelect: PropTypes.bool,
	deleteRouteName: PropTypes.string,
	moveRouteName: PropTypes.string,
	moveRouteAdditionalParameters: PropTypes.object,
	dataUpdatedAt: PropTypes.number,
	reloadedData: PropTypes.func,
	useExport: PropTypes.bool,
	exportUrls: PropTypes.shape({
		csv: PropTypes.string,
	}),
};

AbnOverview.defaultProps = {
	useDragAndDrop: false,
	hideControls: false,
	hideNrOfResults: false,
	usePageLimitSelect: true,
	useExport: false,
	dataUpdatedAt: null,
};
